import styled from 'styled-components';
import bg from '../../Images/Newsletter/newsletter.jpg';

export const NewsContainer = styled.div`
background: linear-gradient(to bottom, rgba(240, 255, 230, 1), rgba(240, 255, 230, 0.8), rgba(255, 255, 255, 1)), url(${bg});
`;

export const NewsWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const Newsbox = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`;

export const NewsHeader = styled.h1`
font-size: 36px;
color: #000;
text-align: center;
margin-bottom: 25px;

@media screen and (max-width: 768px){
    font-size: 28px;
}

@media screen and (max-width: 480px){
    font-size: 24px;
}
`;

export const NewsText = styled.p`
font-size: 18px;
color: #000;
text-align: center;
margin-bottom: 25px;

@media screen and (max-width: 768px){
    font-size: 16px;
}

@media screen and (max-width: 480px){
    font-size: 14px;
}
`;

export const NewsForm = styled.form`
display: flex;
flex-direction: column;
box-sizing: border-box;
width: 100%;
padding: 100px 25px;
justify-content: center;
align-items: center;
border-radius: 0 0 50px 50px;
`;

export const NewsInput = styled.input`
width: 100%;
max-width: 500px;
height: 50px;
margin-top: 5px;
border-radius: 5px;
border: none;
padding-left: 10px;

&:focus {
    border: 2px solid #33682c;
    outline: 0;
}

&:disabled {
    color: #fff;
}
`;

export const NewsButtonText = styled.p`
padding: 15px 40px 15px 40px;
color: #fff;
font-size: 18px;
text-transform: uppercase;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 11px;
}
`;

export const NewsSubmitButton = styled.button`
margin-top: 20px;
max-width: 300px;
margin-left: auto;
margin-right: auto;
border-radius: 20px;
background: rgba(51, 104, 44, 1);
border: 1px solid #fff;
transition: 0.2s all ease-in-out;

&:hover {
    transition: 0.2s all ease-in-out;
    transform: scale(1.05);
    background: rgba(51, 104, 44, 0.8);
    cursor: pointer;
}
`;

export const FeedbackText = styled.p`
margin-top: 25px;
color: #000;
text-align: center;
`;
