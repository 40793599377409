/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMetaMask } from 'metamask-react';
import { useNavigate } from 'react-router-dom';
import useAxiosBasic from '../../hooks/useAxiosBasic';
import {
  WhitelistConnectButton,
  WhitelistButtonText,
  WhitelistContainer,
  WhitelistForm,
  WhitelistInput1,
  WhitelistWrapper,
  WhitelistFormElement,
  WhitelistSubmitButton,
  ConnectField,
  ConnectText,
  FormLabel,
  FeedbackText,
  ConnectTextDesc,
  WhitelistSelect,
  OptionalSummary,
  OptionalDetails,
  LabelDesc,
} from './WhitelistElement';
import axios from '../../api/axios';

const initialState = {
  time: '',
  wallet: '',
  twitter: '',
  discord: '',
  amount: 1,
  referrer: '',
  email: '',
  businessName: '',
  industry: '',
  contactName: '',
  phone: '',
};

const WhitelistSection = () => {
  const { connect, account } = useMetaMask();
  const navigate = useNavigate();
  const axiosBasic = useAxiosBasic();

  const [feedback, setFeedback] = useState('');
  const [sending, setSending] = useState(false);
  const [state, setState] = useState(initialState);
  const [recaptchaValue, setRecaptchaValue] = useState('');
  const [referrers, setReferrers] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      setFeedback('Please verify ReCAPTCHA');
      return;
    }

    setFeedback('Submitting...');
    setSending(true);
    const today = Date.now();

    try {
      axiosBasic.post(
        '/submitWaitlist',
        JSON.stringify({
          time: (new Intl.DateTimeFormat('en-US', {
            timeZone: 'America/Los_Angeles',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }).format(today)),
          wallet: state.wallet,
          email: state.email,
          businessName: state.businessName,
          industry: state.industry,
          contactName: state.contactName,
          phone: state.phone,
          twitter: state.twitter,
          discord: state.discord,
          amount: state.amount,
          referrer: state.referrer,
          responseKey: recaptchaValue,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      ).then((res) => {
        if (res.data.status === 'success') {
          setFeedback(res.data?.message);
          setTimeout(() => { navigate('/'); }, 3000);
        } else {
          setFeedback(res.data?.message);
          setSending(false);
        }
      }).catch((res) => {
        if (res.response?.data) setFeedback(res.response.data.message);
        else if (res?.data) setFeedback(res.data?.message);
        else setFeedback('No server response');
        setSending(false);
      });
    } catch (err) {
      console.log(err);
      setFeedback('Something went wrong. Please try again later.');
      setSending(false);
    }
  };

  const handleInput = (e) => {
    const input = e.currentTarget.name;
    const value = e.currentTarget.value;

    setState((prev) => ({ ...prev, [input]: value }));
  };

  const recaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, wallet: account }));
  }, [account]);

  useEffect(() => {
    try {
      axios.get(
        '/getReferrers',
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        },
      ).then((res) => {
        setReferrers(res.data.referrers);
      }).catch((err) => {
        console.log('Cant get referres ', err);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <WhitelistContainer>
      <WhitelistWrapper>
        {account == null
          ? (
            <ConnectField>
              <ConnectText>Please connect your wallet to join our waitlist</ConnectText>
              <ConnectTextDesc>
                This will not create a transaction. It will
                only add you to the waitlist for our NFT sale.
                The first 2,000 members on our waitlist will also be added to our
                pre-mint Whitelist, which guarantees access to our NFT sale.
              </ConnectTextDesc>
              <ConnectTextDesc>
                When you join our waitlist, we will give you the option
                to input the number of NFTs that you would like to buy. You will also
                have the option to select the organization who referred you to our
                NFT sale.
              </ConnectTextDesc>
              <WhitelistConnectButton type="button" onClick={connect}>
                <WhitelistButtonText>Connect</WhitelistButtonText>
              </WhitelistConnectButton>
            </ConnectField>
          )
          : (
            <>
              <ConnectText style={{ color: '#000', marginBottom: '50px' }}>Our target price is $375 in Matic per NFT</ConnectText>
              <WhitelistForm onSubmit={handleSubmit}>
                <ConnectText style={{ marginBottom: '25px' }}>PRE-MINT WAITLIST FORM</ConnectText>
                <WhitelistFormElement>
                  <FormLabel htmlFor="wallet">
                    Wallet Address:
                    {' '}
                    <span style={{ color: 'red' }}>*</span>
                  </FormLabel>
                  <WhitelistInput1
                    value={account}
                    placeholder=" Connect with Metamask"
                    disabled
                    type="text"
                    name="wallet"
                  />
                </WhitelistFormElement>
                <WhitelistFormElement style={{ marginTop: '25px' }}>
                  <FormLabel htmlFor="amount">
                    Number of NFTs you plan to buy:
                  </FormLabel>
                  <WhitelistInput1
                    value={state.amount}
                    onChange={handleInput}
                    type="number"
                    name="amount"
                    min="1"
                  />
                </WhitelistFormElement>
                <WhitelistFormElement style={{ marginTop: '25px' }}>
                  <FormLabel htmlFor="referrer">Referrer:</FormLabel>
                  <WhitelistSelect
                    value={state.referrer}
                    onChange={handleInput}
                    name="referrer"
                  >
                    <option value="">None</option>
                    {referrers?.map((referrer) => (
                      <option key={referrer.ID} value={referrer.Name}>{referrer.Name}</option>
                    ))}
                  </WhitelistSelect>
                </WhitelistFormElement>
                <OptionalDetails>
                  <OptionalSummary>Optional:</OptionalSummary>
                  <LabelDesc>
                    Input your contact information if you want
                    us to contact you when we launched our NFT sale
                  </LabelDesc>
                  <WhitelistFormElement style={{ marginTop: '25px' }}>
                    <FormLabel htmlFor="businessName">Business Name:</FormLabel>
                    <WhitelistInput1
                      autoComplete="off"
                      value={state.businessName}
                      onChange={handleInput}
                      placeholder=""
                      type="text"
                      name="businessName"
                    />
                  </WhitelistFormElement>
                  <WhitelistFormElement style={{ marginTop: '25px' }}>
                    <FormLabel htmlFor="industry">Industry:</FormLabel>
                    <WhitelistInput1
                      autoComplete="off"
                      value={state.industry}
                      onChange={handleInput}
                      placeholder=""
                      type="text"
                      name="industry"
                    />
                  </WhitelistFormElement>
                  <WhitelistFormElement style={{ marginTop: '25px' }}>
                    <FormLabel htmlFor="contactName">Contact Name:</FormLabel>
                    <WhitelistInput1
                      autoComplete="off"
                      value={state.contactName}
                      onChange={handleInput}
                      placeholder=""
                      type="text"
                      name="contactName"
                    />
                  </WhitelistFormElement>
                  <WhitelistFormElement style={{ marginTop: '25px' }}>
                    <FormLabel htmlFor="phone">Phone Number:</FormLabel>
                    <WhitelistInput1
                      autoComplete="off"
                      value={state.phone}
                      onChange={handleInput}
                      placeholder=""
                      type="tel"
                      name="phone"
                    />
                  </WhitelistFormElement>
                  <WhitelistFormElement style={{ marginTop: '25px' }}>
                    <FormLabel htmlFor="email">E-mail:</FormLabel>
                    <WhitelistInput1
                      autoComplete="off"
                      value={state.email}
                      onChange={handleInput}
                      placeholder=" example@provider.com"
                      type="text"
                      name="email"
                    />
                  </WhitelistFormElement>
                  <WhitelistFormElement style={{ marginTop: '25px' }}>
                    <FormLabel htmlFor="twitter">Twitter:</FormLabel>
                    <WhitelistInput1
                      value={state.twitter}
                      onChange={handleInput}
                      placeholder=" @"
                      type="text"
                      name="twitter"
                    />
                  </WhitelistFormElement>
                  <WhitelistFormElement style={{ marginTop: '25px' }}>
                    <FormLabel htmlFor="discord">Discord:</FormLabel>
                    <WhitelistInput1
                      value={state.discord}
                      onChange={handleInput}
                      placeholder=" example#1111"
                      type="text"
                      name="discord"
                    />
                  </WhitelistFormElement>
                </OptionalDetails>
                <ReCAPTCHA
                  style={{ marginTop: '50px', marginLeft: 'auto', marginRight: 'auto' }}
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  size={window.innerWidth > 768 ? 'normal' : 'compact'}
                  theme="light"
                  onChange={recaptchaChange}
                />
                <FeedbackText>{feedback}</FeedbackText>
                <WhitelistSubmitButton disabled={sending} type="submit">
                  <WhitelistButtonText>{sending ? 'BUSY' : 'SUBMIT'}</WhitelistButtonText>
                </WhitelistSubmitButton>
              </WhitelistForm>
            </>
          )}
      </WhitelistWrapper>
    </WhitelistContainer>
  );
};

export default WhitelistSection;
