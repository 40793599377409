import React from 'react';
import {
  DownloadCard,
  DownloadCardImg,
  DownloadCardtext,
  DownloadCardWrapper,
  DownloadContainer,
  DownloadH1,
  DownloadWrapper,
} from './DownloadsElements';
import img1 from '../../Images/Downloads/download-1.webp';
import img2 from '../../Images/Downloads/download-2.webp';
import img3 from '../../Images/Downloads/download-3.webp';

const DownloadSection = () => (
  <DownloadContainer style={{ paddingTop: '100px', paddingBottom: '100px' }}>
    <DownloadWrapper>
      <DownloadH1>
        Download
        {' '}
        <span style={{ color: '#33682C' }}>Informative</span>
        {' '}
        PDFs
      </DownloadH1>
      <DownloadCardWrapper>
        <DownloadCard href="Downloadables/primer.pdf" download>
          <DownloadCardImg alt="dowload" src={img1} />
          <DownloadCardtext>Download</DownloadCardtext>
        </DownloadCard>
        <DownloadCard href="Downloadables/toc.pdf" download>
          <DownloadCardImg alt="dowload" src={img2} />
          <DownloadCardtext>Download</DownloadCardtext>
        </DownloadCard>
        <DownloadCard href="Downloadables/promotional.pdf" download>
          <DownloadCardImg alt="dowload" src={img3} />
          <DownloadCardtext>Download</DownloadCardtext>
        </DownloadCard>
      </DownloadCardWrapper>
    </DownloadWrapper>
  </DownloadContainer>
);

export default DownloadSection;
