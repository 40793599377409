import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import RightsSection from '../Components/YourRights';

const RightsPage = () => (
  <>
    <ScrollToTop />
    <SubHeroSection header="Your Rights" heroHeight="20rem" />
    <EmptyDivider />
    <RightsSection />
    <EmptyDivider />
  </>
);

export default RightsPage;
