import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const GdrpContainer = styled.div`
position: fixed;
display: flex;
width: 100%;
bottom: 0;
z-index: 999;
background: #33682c;
`;

export const GdrpWrapper = styled.div`
display: flex;
flex-direction: row;
width: 100%;
max-width: 1100px;
margin-left: auto;
margin-right: auto;
padding: 25px 25px;
justify-content: space-between;
align-items: center;
`;

export const GdrpTextWrapper = styled.div`
display: flex;
flex-direction: column;
padding-right: 10px;
`;

export const GdrpText = styled.p`
color: #fff;
font-size: 16px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const GdrpHeader = styled.h1`
color: #fff;
font-size: 18px;
margin-bottom: 10px;

@media screen and (max-width: 768px){
    font-size: 16px;
}

@media screen and (max-width: 480px){
    font-size: 14px;
}
`;

export const GdrpLink = styled(Link)`
color: #fff;
font-size: 16px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const GdrpButton = styled.button`
width: fit-content;
height: fit-content;
font-size: 16px;
color: #000;
background: #fff;
padding: 5px 20px;
border: none;
border-radius: 10px;

&:hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}
`;
