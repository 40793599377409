import styled from 'styled-components';
import { motion } from 'framer-motion';

export const MenuContainer = styled(motion.div)`
position: absolute;
display: block;
right: 0;
top: 0;
width: fit-content;
z-index: 999;
transition: 0.4s all ease-in-out;

@media screen and (max-width: 768px){
    transition: 0.4s all ease-in-out;
    width: 100%;
}
`;

export const MenuWrapper = styled.div`
display: flex;
height: 100vh;
background: #33682C;
transition: 0.2s all ease-in-out;
padding: 0 100px;

@media screen and (max-width: 768px){
    transition: 0.2s all ease-in-out;
    background: #33682C;
    padding: 0;
}
`;

export const MenuItems = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
margin-left: auto;
margin-right: auto;
gap: 25px;

@media screen and (max-width: 768px){
    gap: 20px;
    align-items: center;
}

@media screen and (max-width: 480px){
    gap: 20px;
    align-items: center;
}
`;

export const Item = styled.p`
color: #fff;
font-size: 18px;
transition: all 0.2s ease-in-out;

@media screen and (max-width: 768px){
    font-size: 16px;
}

@media screen and (max-width: 480px){
    font-size: 14px;
}

&:hover {
    transform: scaleX(1.1);
    transition: all 0.2s ease-in-out; 
    cursor: pointer;
}
`;

export const CloseButtonWrapper = styled.div`
position: absolute;
right: 0;
margin-top: 50px;
margin-right: 10%;
`;

export const CloseButton = styled.button`
background: #fff;
border: none;
border-radius: 100%;
width: 50px;
height: 50px;

@media screen and (max-width: 768px){
    width: 40px;
    height: 40px;
}

&:hover {
    transform: scale(1.05);
    cursor: pointer;
}
`;

export const MenuButtonWrapper = styled.div`
margin-top: 5%;
display: flex;
flex-direction: column;
gap: 25px;

@media screen and (max-width: 768px){
    transition: 0.2s all ease-in-out;
    flex-direction: row;
}

@media screen and (max-width: 480px){
    transition: 0.2s all ease-in-out;
    flex-direction: column;
    gap: 15px;
}
`;

export const MenuButton = styled.a`
border: 1px solid #fff;
background: rgba(255,255,255, 0.2);
padding: 20px 40px 20px 40px;
color: #fff;
text-decoration: none;
border-radius: 10px;
font-size: 16px;

@media screen and (max-width: 768px){
    transition: 0.2s all ease-in-out;
    padding: 10px 20px 10px 20px;
}

@media screen and (max-width: 480px){
    font-size: 14px;
}

&:hover {
    transition: 0.2s all ease-in-out;
    background: rgba(255,255,255, 0.4);
    cursor: pointer;
}
`;

export const MenuButton2 = styled.a`
border: 1px solid #fff;
background: rgba(255,255,255, 0.2);
padding: 20px 40px 20px 40px;
color: #fff;
text-decoration: none;
border-radius: 10px;
font-size: 16px;

@media screen and (max-width: 768px){
    transition: 0.2s all ease-in-out;
    padding: 10px 20px 10px 20px;
}

@media screen and (max-width: 480px){
    font-size: 14px;
}

&:hover {
    transition: 0.2s all ease-in-out;
    background: rgba(255,255,255, 0.4);
    cursor: pointer;
}
`;

export const SocialIconsWrapper = styled.div`
display: none;
justify-content: center;
margin-left: auto;
margin-right: auto;
gap: 25px;
margin-top: 25px;

@media screen and (max-width: 768px){
    transition: 0.2s all ease-in-out;
    display: flex;
}
`;

export const SocialIcon = styled.a`
color: #fff;
font-size: 28px;

@media screen and (max-width: 768px){
    font-size: 26px;
}

@media screen and (max-width: 480px){
    font-size: 24px;
}

&:hover {
    transform: scale(1.05);
    cursor: pointer;
}
`;

export const Divider = styled.div`
height: 1px;
width: 100%;
border-bottom: 2px solid #fff;
`;
