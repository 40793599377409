import styled from 'styled-components';

export const DownloadContainer = styled.div`
background: #F0FFE6;
`;

export const DownloadWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const DownloadCardWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
text-align: center;
gap: 5%;
margin-top: 50px;

@media screen and (max-width: 768px){
    flex-direction: column;
    gap: 5rem;
}
`;

export const DownloadH1 = styled.h1`
font-size: 56px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 42px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const DownloadCardtext = styled.p`
position: absolute;
text-align: center;
text-decoration: none;
color: #fff;
font-size: 0px;
text-shadow: rgba(16, 17, 16, 0.2) 3px 5px;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
`;

export const DownloadCard = styled.a`
width: 100%;
height: 100%;

&:hover {
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    transform: scale(1.05);
}

&:hover ${DownloadCardtext} {
    transition: 0.2s all ease-in-out;
    font-size: 36px;

    @media screen and (max-width: 768px){
        font-size: 32px;
    }

    @media screen and (max-width: 480px){
        font-size: 28px;
    }
}
`;

export const DownloadCardImg = styled.img`
width: 100%;
max-width: 400px;
height: auto;
border-radius: 20px;

@media screen and (max-width: 768px){
    max-width: 300px;
}

@media screen and (max-width: 480px){
    max-width: 250px;
}
`;
