/* eslint-disable no-const-assign */
/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import {
  FeedbackText,
  FormLabel,
  LabelDesc,
  SearchButtonText,
  SearchContainer,
  SearchForm,
  SearchH1,
  SearchSubmitButton,
  SearchtInput,
  SearchWrapper,
} from './SearchElements';

const key = '6Ld_nVUjAAAAADq5stUU6K9qATHBT0kHn27pmEpT';

const SearchSection = () => {
  const [normalValue, setNormalValue] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const [feedback, setFeedback] = useState('');
  const [searching, setSearching] = useState(false);

  const recaptchaRef = React.useRef();
  const navigate = useNavigate();

  const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, '');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formattedValue === '') {
      setFeedback('You need to type NFT number');
      return;
    }

    if (normalValue > 5750) {
      setFeedback('Max number is 5,750');
      setFormattedValue('');
      setNormalValue('');
      return;
    }

    if (normalValue < 1) {
      setFeedback('Min number is 1');
      setFormattedValue('');
      setNormalValue('');
      return;
    }

    const token = await recaptchaRef.current.executeAsync();

    if (token === false) {
      setFeedback('Please verify ReCAPTCHA');
      return;
    }

    setFeedback('');
    setSearching(true);
    setTimeout(() => { navigate(`/my_hectare/avatime_test/${normalValue}`); }, 1000);
    // setTimeout(() => { window.open(`https://satnavnfts.com/my_hectare/avatime_test/${normalValue}`); window.location.reload(); }, 1000);
  };

  const handleInput = (e) => {
    const value = e.currentTarget.value;

    setNormalValue(value.replace(/,/g, ''));
    setFormattedValue(addCommas(removeNonNumeric(value)));
  };

  return (
    <SearchContainer>
      <SearchWrapper>
        <SearchForm onSubmit={handleSubmit}>
          <SearchH1>GO TO YOUR FOREST</SearchH1>
          <LabelDesc>
            Input your NFT number to navigate
            to your dedicated plot of forest land.
          </LabelDesc>
          <FormLabel>Token number:</FormLabel>
          <SearchtInput
            value={formattedValue}
            onChange={handleInput}
            placeholder="Type here"
            type="text"
            name="amount"
          />
          <ReCAPTCHA
            style={{ marginTop: '50px', marginLeft: 'auto', marginRight: 'auto' }}
            sitekey={key}
            ref={recaptchaRef}
            size="invisible"
            theme="light"
          />
          <FeedbackText>{feedback}</FeedbackText>
          <SearchSubmitButton type="submit"><SearchButtonText>{searching ? 'NAVIGATING...' : 'NAVIGATE TO MY PLOT'}</SearchButtonText></SearchSubmitButton>
        </SearchForm>
      </SearchWrapper>
    </SearchContainer>
  );
};

export default SearchSection;
