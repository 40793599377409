import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
margin-top: 2rem;
margin-bottom: 2rem;
`;

const EmptyMediumDivider = () => (
  <Divider />
);

export default EmptyMediumDivider;
