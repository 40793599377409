import React from 'react';
import {
  AboutUsBrand,
  AboutUsContainer,
  AboutUsDesc,
  AboutUsHeader,
  AboutUsImage,
  AboutUsWrapper,
  AboutUsPart,
  Column1,
  Column2,
  AboutUsBrandContainer,
  TextWrapper,
} from './AboutUsElements';
import img1 from '../../Images/AboutUs/about5.png';

const AboutUsSection = () => (
  <AboutUsContainer>
    <AboutUsWrapper>
      <AboutUsPart>
        <Column1>
          <AboutUsHeader>
            Our goal is to minimize
            {' '}
            <span style={{ color: '#33682C' }}>poverty-driven</span>
            {' '}
            deforestation
          </AboutUsHeader>
          <AboutUsDesc>
            The main goal of our project is to minimize poverty-driven
            deforestation by adding equity and inclusion to climate finance
            and by increasing the value of forests to their local communities.
          </AboutUsDesc>
        </Column1>
        <Column2>
          <AboutUsImage src={img1} alt="about1" />
        </Column2>
      </AboutUsPart>
      <TextWrapper>
        <AboutUsHeader style={{ marginTop: '50px' }}>
          Our
          {' '}
          <span style={{ color: '#33682C' }}>mission</span>
          {' '}
          is to increase supply and demand for forest climate projects.
        </AboutUsHeader>
        <AboutUsDesc>
          We help increase supply by geolocating biodiversity that we bundle
          with high-quality carbon credits into an NFT. This increases their
          combined value and price, which attracts more climate funding and
          provides equity for financially disadvantaged forest communities.
          <br />
          <br />
          We increase demand by leveraging ReFi to transform $1 of climate
          sponsorship into $1 of regenerative entrepreneurship, which delivers
          $1 of environmental (“E”) regeneration, $1 of social impact (“S”) and
          yields a $1 return to our locally governed (“G”) social impact fund.
          This multiplies, recycles and compounds ESG sponsorship. The outcome is
          enhanced transparency, provenance and attribution of biodiverse benefits
          to NFT holders
        </AboutUsDesc>
      </TextWrapper>
    </AboutUsWrapper>
    <AboutUsBrandContainer>
      <AboutUsWrapper>
        <AboutUsBrand>
          Carbon Collectible® NFTs is a brand name of ProcessGuru, Inc.,
          (UEI: NJ1ULGJDVWU5, CAGE 90UH2) a minority certified corporation (NMSDC SC07505)
          incorporated in the state of California (C2408165).
        </AboutUsBrand>
        <AboutUsBrand>
          Carbon Collectible® is a USPTO trademark registered (97089112) by Privity
          Ventures Inc. on behalf of ProcessGuru, Inc.
        </AboutUsBrand>
      </AboutUsWrapper>
    </AboutUsBrandContainer>
  </AboutUsContainer>
);

export default AboutUsSection;
