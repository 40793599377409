/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { useNavigate } from 'react-router-dom';
import {
  CoordsP,
  NavContainer,
  NavDesc,
  NavHeader,
  NavWrapper,
  NavDesc2,
  CarbonButton,
} from './SetNavElements';

const parse_georaster = require('georaster');
const GeoRasterLayer = require('georaster-layer-for-leaflet');

const SetNavSection = ({ hectare, nftID }) => {
  const [nftData, setNftData] = useState(null);
  const [roiLat, setRoiLat] = useState(null);
  const [roiLng, setRoiLng] = useState(null);
  const [soiLat, setSoiLat] = useState(null);
  const [soiLng, setSoiLng] = useState(null);
  let createdMap;
  let savedRoaster;

  const [errorLoad, setErrorLoad] = useState(false);
  const [feedback, setFeedback] = useState('');
  const navigate = useNavigate();

  function loadHectareLatLng() {
    const hlat = parseFloat(nftData.hLatLng[0]);
    const hlng = parseFloat(nftData.hLatLng[1]);
    return [hlat, hlng];
  }

  function getCoords(id) {
    return document.getElementById(id).innerHTML.split(',').map((cv) => parseFloat(cv));
  }

  function getLatlng(suffix) {
    const latCoords = getCoords(`latitude-coords-${suffix}`);
    const lngCoords = getCoords(`longitude-coords-${suffix}`);
    return latCoords.map((cv, j) => [cv, lngCoords[j]]);
  }

  function getGeoTiffPath() {
    const roiName = window.location.pathname.split('/')[2];
    const path = `/static/data/${roiName}_net_carbon.tif`;
    return path;
  }

  function genGetSumIndex(index) {
    function getSum(total, val) {
      return total + parseFloat(val[index]);
    }
    return getSum;
  }

  function computeLatLngMean(array) {
    if (array.length === 0) {
      return [0.0, 0.0];
    }
    const latMean = array.reduce(genGetSumIndex(0), 0.0) / array.length;
    const lngMean = array.reduce(genGetSumIndex(1), 0.0) / array.length;
    return [latMean, lngMean];
  }

  const popupMessage = (amt, latVal, lngVal) => `${amt} tons / year / hectare net Carbon removed (${latVal}, ${lngVal})`;

  function colorMap(colorCode) {
    if (colorCode > 0 && colorCode <= 2) {
      return '#FFCDD2';
    } if (colorCode > 2 && colorCode <= 4) {
      return '#EF9A9A';
    } if (colorCode > 4 && colorCode <= 6) {
      return '#E57373';
    } if (colorCode > 6 && colorCode <= 8) {
      return '#EF5350';
    } if (colorCode > 8 && colorCode <= 10) {
      return '#F44336';
    } if (colorCode > 10 && colorCode <= 12) {
      return '#FF8A80';
    } if (colorCode > 12 && colorCode <= 14) {
      return '#FF5252';
    } if (colorCode > 14) {
      return '#FF0099';// '#D50000';
    }
    return null;
  }

  function colorFn(values) {
    if (!(Object.prototype.toString.call(values) === '[object Array]'
        && values.length === 1)) {
      return null;
    }
    const colorCode = parseFloat(values[0]);
    return colorMap(colorCode);
  }

  function latLngWithinRaster(lat, lng, raster) {
    const ax = raster.pixelWidth;
    const ay = -raster.pixelHeight;
    const delta = ax / 2.0;
    const yIdx = Math.round((lat - raster.ymax) / ay);
    const xIdx = Math.round((lng - raster.xmin) / ax);

    const latR = yIdx * ay + raster.ymax - delta + 0.2 * delta;
    const lngR = xIdx * ax + raster.xmin + delta;

    const xWithin = xIdx >= 1 && xIdx < raster.width;
    const yWithin = yIdx >= 0 && yIdx < raster.height;

    const hectareBounds = [L.latLng(latR - delta, lngR - delta),
      L.latLng(latR + delta, lngR + delta)];

    return {
      pointInRaster: xWithin && yWithin,
      xIdx,
      yIdx,
      latR,
      lngR,
      delta,
      hectareBounds,
    };
  }

  function drawHectacre(map, popup, raster, lat, lng, timeout) {
    const pointInfo = latLngWithinRaster(lat, lng, raster);
    const latLng = L.latLng(pointInfo.latR, pointInfo.lngR);
    let msg = 'No data available';
    if (pointInfo.pointInRaster) {
      const box = L.rectangle(
        pointInfo.hectareBounds,
        {
          color: 'black',
          weight: 1,
          fillOpacity: 0.2,
        },
      ).addTo(map);
      const val = raster.values[0][pointInfo.yIdx][pointInfo.xIdx];

      msg = val > 0 ? popupMessage(String(val.toPrecision(3)), lat.toPrecision(5), lng.toPrecision(5)) : 'No data available';
      if (timeout) {
        setTimeout(() => { box.remove(map); }, timeout);
      }
    }
    if (!popup.isOpen()) {
      popup.setLatLng(latLng).setContent(msg).openOn(map);
    } else {
      popup.setLatLng(latLng).setContent(msg).update();
    }
  }

  function genIndexerOnMapClick(map, popup, raster, timeout = 5000) {
    function onMapClick(e) {
      const { lat } = e.latlng;
      const { lng } = e.latlng;
      drawHectacre(map, popup, raster, lat, lng, timeout);
    }
    return onMapClick;
  }

  function loadMapROIAndSOI() {
    const soiCoords = getLatlng('soi');
    const roiCoords = getLatlng('roi');
    const latLngMean = computeLatLngMean(roiCoords);

    createdMap = L.map('map').setView(latLngMean, 10);
    const polygon = L.polygon(roiCoords, { fillOpacity: 0.0 }).addTo(createdMap);
    soiCoords.forEach((cv, j) => {
      L.circle(cv, {
        color: 'red',
        fillColor: '#f03',
        fillOpacity: 0.5,
        radius: 200,
      }).addTo(createdMap);
    });
    return createdMap;
  }

  function loadSatelliteData(map) {
    L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 20,
      id: 'mapbox/satellite-v9',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: 'pk.eyJ1IjoiZGF0YWdsYWRpYXRvciIsImEiOiJja3hlemd6OGUxeHNyMm9vY3M1aGNqdTVmIn0.6rpgypC4oiSNH0SvRvXTRg',
    }).addTo(map);
  }

  function loadCarbonAndFlyToHectare(map, popup, hectareLatLng) {
    const geotiffPath = getGeoTiffPath();
    const hlat = hectareLatLng[0];
    const hlng = hectareLatLng[1];

    fetch(geotiffPath)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        parse_georaster(arrayBuffer).then((georaster) => {
          savedRoaster = georaster;
          map.on('click', genIndexerOnMapClick(map, popup, georaster));
          // https://leafletjs.com/reference-1.2.0.html#gridlayer
          const layer = new GeoRasterLayer({
            georaster,
            opacity: 0.4,
            pixelValuesToColorFn: colorFn,
            resolution: 64,
          });
          let roi_bounds = layer.getBounds();
          roi_bounds = [roi_bounds.getSouthWest(), roi_bounds.getNorthEast()];
          map.fitBounds(roi_bounds);
          layer.addTo(map);
          drawHectacre(map, popup, georaster, hlat, hlng, null);
          map.flyTo([hlat, hlng], 17, { duration: 5.0 });
        });
      });
  }

  function myHectareMapper() {
    const map = loadMapROIAndSOI();
    loadSatelliteData(map);
    const hectareLatLng = loadHectareLatLng();
    const popup = L.popup().setLatLng(hectareLatLng).setContent('').openOn(map);
    loadCarbonAndFlyToHectare(map, popup, hectareLatLng);
  }

  useEffect(async () => {
    try {
      await fetch(`https://satnavapp.com/hectare_api/${hectare}/${nftID}`)
        .then(async (r) => {
          const body = await r.json();
          if (body.status === 500) {
            setErrorLoad(true);
            setFeedback('Unknown hectare');
            setTimeout(() => {
              navigate('/go');
            }, 3000);
          } else if (body.status === 200) {
            setNftData(body.data);
          } else {
            setErrorLoad(true);
            setFeedback('Something went wrong. Please check your input');
            setTimeout(() => {
              navigate('/go');
            }, 3000);
          }
        })
        .catch(async (err) => {
          setErrorLoad(true);
          setFeedback('Something went wrong when fetching data');
          setTimeout(() => {
            navigate('/go');
          }, 3000);
        });
    } catch (err) {
      setErrorLoad(true);
      setFeedback('Cant access to servers. Please try again later...');
      setTimeout(() => {
        navigate('/go');
      }, 3000);
    }
  }, []);

  function resetView() {
    const hectareLatLng = loadHectareLatLng();
    const hlat = hectareLatLng[0];
    const hlng = hectareLatLng[1];

    const popup = L.popup().setLatLng(hectareLatLng).setContent('').openOn(createdMap);
    drawHectacre(createdMap, popup, savedRoaster, hlat, hlng, null);

    createdMap.setView([hlat, hlng], 17, { duration: 5.0 });
  }

  useEffect(() => {
    if (nftData != null) {
      setRoiLat(JSON.stringify(nftData.roi_coords[0]).replace(/\[/g, '').replace(/\]/g, ''));
      setRoiLng(JSON.stringify(nftData.roi_coords[1]).replace(/\[/g, '').replace(/\]/g, ''));
      setSoiLat(JSON.stringify(nftData.soi_info[0]).replace(/\[/g, '').replace(/\]/g, ''));
      setSoiLng(JSON.stringify(nftData.soi_info[1]).replace(/\[/g, '').replace(/\]/g, ''));
    }
  }, [nftData]);

  useEffect(() => {
    if (soiLng != null) {
      myHectareMapper();
    }
  }, [soiLng]);

  function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <NavContainer>
      <NavWrapper>
        {errorLoad ? (
          <>
            <NavHeader>ERROR</NavHeader>
            <NavDesc>{feedback}</NavDesc>
            <NavDesc>Directing to search page...</NavDesc>
          </>
        ) : (
          <>
            {/* <NavHeader>{`Welcome to ${Capitalize(hectare.replace(/_/g, ' '))}`}</NavHeader> */}
            <NavDesc>Annual net carbon sequestration per hectare in tons</NavDesc>
            <NavDesc2>{`Displaying NFT ID: ${nftID}`}</NavDesc2>
            <CoordsP id="latitude-coords-roi">{roiLat}</CoordsP>
            <CoordsP id="longitude-coords-roi">{roiLng}</CoordsP>
            <CoordsP id="latitude-coords-soi">{soiLat}</CoordsP>
            <CoordsP id="longitude-coords-soi">{soiLng}</CoordsP>
            <div style={{ marginTop: '25px' }} id="map">
              <CarbonButton type="button" id="refreshButton" onClick={() => resetView()}>Reset position</CarbonButton>
            </div>
          </>
        )}
      </NavWrapper>
    </NavContainer>
  );
};
export default SetNavSection;
