import styled from 'styled-components';

export const NotFoundContainer = styled.div`

`;

export const NotFoundWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const NotFoundHeader = styled.h1`
font-size: 36px;
color: #000;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 28px;
}

@media screen and (max-width: 480px){
    font-size: 24px;
}
`;

export const NotFoundDesc = styled.p`
font-size: 18px;
color: #a9a9a9;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const NotFoundImage = styled.img`
width: 100%;
max-width: 600px;
height: auto;
margin-top: 100px;
margin-left: auto;
margin-right: auto;
`;
