import styled from 'styled-components';

export const SearchContainer = styled.div`

`;

export const SearchWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;

@media screen and (max-width: 768px){
    font-size: 14px;
    text-align: center;
}

@media screen and (max-width: 480px){
    font-size: 11px;
    text-align: center;
}
`;

export const SearchForm = styled.form`
display: flex;
flex-direction: column;
margin-left: auto;
margin-right: auto;
border: 3px solid #fff;
border-radius: 20px;
padding: 4rem 5rem;
background-image: linear-gradient(to right top, #003d01, #1c5217, #33682c, #4a7f41, #619657);
box-shadow: 0 0 10px 10px rgba(0,0,0,0.2);

@media screen and (max-width: 768px){
    padding: 4rem 3rem;
}
`;

export const SearchH1 = styled.h1`
color: #fff;
font-size: 36px;
text-align: center;
margin-left: auto;
margin-right: auto;

@media screen and (max-width: 768px){
    font-size: 28px;
}

@media screen and (max-width: 480px){
    font-size: 22px;
}
`;

export const SearchtInput = styled.input`
width: 100%;
height: 40px;
margin-top: 5px;
border-radius: 5px;
border: none;
padding-left: 10px;

&:disabled {
    color: #fff;
}
`;

export const FormLabel = styled.label`
color: #fff;
text-align: start;
margin-top: 50px;
`;

export const SearchSubmitButton = styled.button`
margin-top: 10px;
max-width: 300px;
margin-left: auto;
margin-right: auto;
border-radius: 20px;
background: rgba(255,255,255,0.2);
border: 1px solid #fff;

&:hover {
    transition: 0.2s all ease-in-out;
    background: rgba(255,255,255,0.4);
    transform: scale(1.05);
    cursor: pointer;
}
`;

export const SearchButtonText = styled.p`
padding: 15px 40px 15px 40px;
color: #fff;
font-size: 18px;
text-transform: uppercase;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 11px;
}
`;

export const LabelDesc = styled.p`
color: #fff;
font-size: 14px;
margin-top: 10px;
font-weight: 200;
text-align: start;

@media screen and (max-width: 768px){
    text-align: center;
}
`;

export const FeedbackText = styled.p`
margin-top: 10px;
color: #fff;
text-align: center;
`;
