import styled from 'styled-components';

export const VotanomicsContainer = styled.div`

`;

export const VotanomicsWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const VotanomicsH1 = styled.h1`
font-size: 56px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 42px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const VotanomicsP = styled.p`
font-size: 18px;
color: #101110;
margin-top: 20px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const VotanomicsImage = styled.img`
width: 100%;
max-width: 1000px;
height: auto;
margin-left: auto;
margin-right: auto;
margin-top: 50px;
`;
