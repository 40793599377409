import React from 'react';
import {
  CardDesc, CardHeader, CardImage, CardWrapper,
} from './CardElements';

const Card = ({
  image, header, desc, first, last,
}) => (
  <CardWrapper first={first} last={last}>
    <CardImage alt="card" src={image} />
    <CardHeader>{header}</CardHeader>
    <CardDesc>{desc}</CardDesc>
  </CardWrapper>
);

export default Card;
