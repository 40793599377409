import styled from 'styled-components';

export const ContributeContainer = styled.div`

`;

export const ContributeWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const ContributeH1 = styled.h1`
font-size: 56px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 42px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const ContributeP = styled.p`
font-size: 18px;
color: #101110;
margin-top: 20px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const ContributeTable = styled.table`
border: 2px solid #33682C;
border-collapse: collapse;
margin: 0;
padding: 0;
width: 100%;
table-layout: fixed;
background: #F0FFE6;
margin-top: 50px;
`;

export const TableTr = styled.tr`
@media screen and (max-width: 768px){
    display: block;
}
`;

export const TableTh = styled.th`
border: 2px solid #33682C;
padding: 10px;
font-size: 18px;

@media screen and (max-width: 768px){
    font-size: 12px;
    border: none;
}

@media screen and (max-width: 480px){
    font-size: 10px;
}
`;

export const TableTd = styled.td`
border: 2px solid #33682C;
padding: 10px;
font-size: 18px;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 12px;
    display: block;
    text-align: right;
    border: 1px solid #33682C;

    &::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }
}

@media screen and (max-width: 480px){
    font-size: 10px;
}
`;

export const TableThead = styled.thead`
@media screen and (max-width: 768px){
    clip: rect(0 0 0 0);
    position: absolute;
}
`;
