import React from 'react';
import {
  Column1,
  Column2,
  ColumnWrapper,
  InnovationContainer,
  InnovationH1,
  InnovationHeader,
  InnovationImage,
  InnovationP,
  InnovationWrapper,
} from './InnovationElements';
import img1 from '../../Images/Innovation/inovation-1.png';

const InnovationSection = () => (
  <InnovationContainer id="innovation" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
    <InnovationWrapper>
      <ColumnWrapper>
        <Column1>
          <InnovationHeader>Our ReFi Social Innovation Studio</InnovationHeader>
          <InnovationH1>
            Our social innovation studio transforms
            {' '}
            <span style={{ color: '#33682C' }}>disenfranchised</span>
            {' '}
            youth into crypto entrepreneurs
          </InnovationH1>
          <InnovationP>
            The objective of our ReFi Social Innovation Studio is to transform disenfranchised
            youth into crypto entrepreneurs.
            <br />
            <br />
            We use 50% of the net proceeds from this NFT sale to fund our ReFi Social Innovation
            Studio. First, we solicit challenges and solutions from the local forest community.
            Then we motivate our international crypto community to help local entrepreneurs
            leverage Web3 innovations to reimagine sustainable and profitable solutions to legacy
            challenges. The outcomes include local entrepreneurship, alternative livelihoods,
            local jobs, economic growth, and hope.
          </InnovationP>
        </Column1>
        <Column2>
          <InnovationImage data-aos="fade" data-aos-once="true" data-aos-delay="100" alt="innovation" src={img1} />
        </Column2>
      </ColumnWrapper>
    </InnovationWrapper>
  </InnovationContainer>
);

export default InnovationSection;
