import React from 'react';
import {
  NotFoundContainer, NotFoundDesc, NotFoundHeader, NotFoundImage, NotFoundWrapper,
} from './NotFoundElements';
import Image from '../../Images/NotFound/notFound.svg';

const NotFoundSection = () => (
  <NotFoundContainer>
    <NotFoundWrapper>
      <NotFoundHeader>SORRY, PAGE NOT FOUND</NotFoundHeader>
      <NotFoundDesc>The page you have been looking for is not found...</NotFoundDesc>
      <NotFoundImage src={Image} alt="Not found" />
    </NotFoundWrapper>
  </NotFoundContainer>
);

export default NotFoundSection;
