import styled from 'styled-components';

export const VideoContainer = styled.div`

`;

export const VideoWrapper = styled.div`
display: flex;
width: 80%;
height: 800px;
margin-left: auto;
margin-right: auto;

@media screen and (max-width: 1400px){
    height: 400px;
}

@media screen and (max-width: 768px){
    height: 200px;
}
`;

export const PlayerWrapper = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 80%;
height: auto;
margin-left: auto;
margin-right: auto;
position: relative;
`;
