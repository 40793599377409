import styled from 'styled-components';

export const CookiesContainer = styled.div`

`;

export const CookiesWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
max-width: 1100px;
margin-left: auto;
margin-right: auto;
padding: 0 25px;
`;

export const CookiesSegment = styled.div`
display: flex;
flex-direction: column;
margin-top: ${({ space }) => (space)};
`;

export const CookiesHeader = styled.h3`
color: #000;
font-size: 24px;
margin-bottom: 2rem;

@media screen and (max-width: 768px){
    font-size: 18px;
}

@media screen and (max-width: 480px){
    font-size: 16px;
}
`;

export const CookiesText = styled.p`
color: #000;
font-size: 16px;
margin-top: ${({ space }) => (space)};

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const Bold = styled.span`
font-weight: bold;
`;

export const CookiesList = styled.ul`
margin: 0;  
padding-left: 20px; 
`;

export const CookiesListElement = styled.li`
color: #000;
font-size: 16px;
margin-top: ${({ space }) => (space)};

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;
