import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import TermsSection from '../Components/TermsAndConditions';

const termsAndConditionsPage = () => (
  <>
    <ScrollToTop />
    <SubHeroSection header="Terms & Conditions" heroHeight="20rem" />
    <EmptyDivider />
    <TermsSection />
    <EmptyDivider />
  </>
);

export default termsAndConditionsPage;
