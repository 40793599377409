/* eslint-disable max-len */
import React from 'react';
import {
  PrivacyContainer,
  PrivacyHeader,
  PrivacySegment,
  PrivacyText,
  PrivacyWrapper,
  PrivacyMainHeader,
  Bold,
  PrivacyList,
  PrivacyListElement,
} from './privacyElements';
import EmptySmallDivider from '../../Utils/EmptyDivider/smallDivider';

const PrivacySection = () => (
  <PrivacyContainer>
    <PrivacyWrapper>
      <PrivacyMainHeader>PLEASE READ THIS PRIVACY STATEMENT CAREFULLY</PrivacyMainHeader>
      {/* Segment */}
      <PrivacySegment>
        <PrivacyHeader>1. Welcome</PrivacyHeader>
        <PrivacyText>
          <Bold>The Company</Bold>
          {' '}
          respects the privacy of every user (data subject), and complies with all Applicable
          laws for the protection of data subject privacy, including, without limitation, all required
          Directives on Data protection regulations of where you are accesing the NFT site. Please note
          that all personal data is duly encrypted and sited in secured servers.
        </PrivacyText>
        <EmptySmallDivider />
        <PrivacyText>
          The
          {' '}
          <Bold>Company</Bold>
          {' '}
          site is the data controller of your Personal Data. When this Privacy Policy mentions
          “we,” “us,” or “our,” it refers to the relevant
          {' '}
          <Bold>Comino Investments SRL</Bold>
          {' '}
          entity accessible
          {' '}
          <Bold>
            at
            https://Carboncollectiblenfts.com/
          </Bold>
          {' '}
          which is providing the website and NFTs directly and
          specifically.
        </PrivacyText>
        <EmptySmallDivider />
        <PrivacyText>
          By using the website, you hereby consent to our Privacy Policy and agree to its terms herein. If
          you are not comfortable with any aspect of this Privacy Policy. You should immediately
          discontinue your access to the NFT Site.
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>2. Personal Information Collection</PrivacyHeader>
        <PrivacyText>
          For the provision of our NFTs, we may collect and process the following personal data about you,
          but only with your consent;
        </PrivacyText>
        <EmptySmallDivider />
        <PrivacyList>
          <PrivacyListElement>
            <Bold>Wallet Information:</Bold>
            {' '}
            When you connect an external wallet to purchase and hold the
            {' '}
            <Bold>Company</Bold>
            {' '}
            NFT, we will ask you to provide a wallet address.
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            <Bold>Communication Information:</Bold>
            {' '}
            We may collect information when you reach out to us with
            questions or complaints; sometimes we collect information from you when you
            voluntarily fill out our questionnaires, surveys or requests for market research seeking
            your opinion and feedback;
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            <Bold>Divice Identifiers:</Bold>
            {' '}
            Information relating to your Computer or mobile Devices – this
            information includes: The type of mobile, tablet, PC, or browser you use when you access
            the site (e.g. manufacturer, MAC address, model, operating system, screen dimensions,
            OS version, Virtual Machine name and version and IP Address).
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            <Bold>Usage Information:</Bold>
            {' '}
            Information about how you use our Service, such as the types of
            content that you view or engage with, the features you use, the actions you take, and the
            time, frequency, and duration of your activities.
          </PrivacyListElement>
        </PrivacyList>
        <EmptySmallDivider />
        <PrivacyText>
          By purchasing or accessing the Company Site or through any other means, you acknowledge that
          you have read this Policy, understand it, agree to its terms, and authorize us to collect, use and
          disclose information pursuant to this Policy
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>3. Personal Information Use</PrivacyHeader>
        <PrivacyText>
          We use the information we collect in various ways, including to:
        </PrivacyText>
        <EmptySmallDivider />
        <PrivacyList>
          <PrivacyListElement>
            Provide, operate, and maintain our website;
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            Administer purchase of the Company NFT;
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            Organize pre sale events, Perks and promotions;
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            Understand and analyze how you interact with our NFT collections;
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            Develop new products, services, features, and functionality;
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            Comply with applicable laws, lawful requests, and legal process, such as to respond to
            subpoenas or requests from government authorities;
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            Protect us, you or others rights, privacy, safety or property (including by making and
            defending legal claims);
          </PrivacyListElement>
        </PrivacyList>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>4. Children</PrivacyHeader>
        <PrivacyText>
          The
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          services are not directed to children who are under the age of 18. We comply
          with the United State COPPA Children Privacy Law. For this reason, we do not knowingly collect
          personal information from children under the age of 18. If you believe that a child under the age
          of 18 provided personal information to us please contact us and we will delete that information
          immediately.
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>5. Links To Other Websites</PrivacyHeader>
        <PrivacyText>
          The
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          Site may contain links to other sites not owned or operated by
          {' '}
          <Bold>Company</Bold>
          , (“Third
          Party Sites”). The information that you share with Third Party Sites will be governed by the
          specific terms of service and privacy policies of the Third Party Site you accessed. The
          {' '}
          <Bold>Company</Bold>
          does not endorse or review these sites. Please contact the Third Party Sites directly for
          information on their privacy practices and terms.
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>6. Security</PrivacyHeader>
        <PrivacyText>
          We have put in place numerous technical, organizational and physical safeguards measures
          designed to protect the personal information of users. However, no online security measures are
          guaranteed. You agree that your use of the
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          site is at your own risk.
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>7. Your rights and Choices as provided under the GDPR?</PrivacyHeader>
        <PrivacyText>
          Under the General Data Protection Regulation, you have the right to
        </PrivacyText>
        <EmptySmallDivider />
        <PrivacyList>
          <PrivacyListElement>
            <Bold>Access,</Bold>
            {' '}
            (i.e. you may ask us to send you a copy of the data about you that We collected);
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            <Bold>Rectify,</Bold>
            {' '}
            (i.e. you may ask us to correct the outdated or false data, or complete the data
            which is missing or incomplete)
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            <Bold>Port</Bold>
            {' '}
            and (i.e. you may ask us to transfer a copy of your data to another organization or to
            you provided that the circumstances comply with the GDPR).
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            <Bold>Erase your data</Bold>
            {' '}
            (i.e. you may ask us to delete the personal data We retain, provided that
            the GDPR allows you to do so);
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            Object to and (if the GDPR reserves your right to object as to certain kinds of processing
            or in certain circumstances);
          </PrivacyListElement>
          <PrivacyListElement space="10px">
            <Bold>Restrict</Bold>
            {' '}
            certain processing of your data (direct marketing and/or &quot;unsubscribe&quot;)
          </PrivacyListElement>
        </PrivacyList>
        <EmptySmallDivider />
        <PrivacyText>
          Learn more about these rights, and find out how you can exercise your rights by sending us a
          message.
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>8. Brexit Notice</PrivacyHeader>
        <PrivacyText>
          Based on the agreement between the UK and the EU, until 2025 all the personal data transfers
          from the EU to the UK are not considered as transfers to a third country. For the transfer from
          the UK to the EU, UK finds this transfer as transfer with adequate protection, so currently there
          are no additional requirements needed for such personal data transfers.
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>9. Notice to California Users</PrivacyHeader>
        <PrivacyText>
          The California Consumer Privacy Act (“CCPA”), regulates how we handle personal data of
          California residents and gives California residents certain rights with respect to their personal
          data. The general privacy policy shall continue to apply to the extent that it applies to you as a
          resident of California. If you are a resident of California, we are required to disclose certain uses
          and disclosures in a certain format, as well as to inform you of certain rights you may have.
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>10. Changes To our Privacy Policy</PrivacyHeader>
        <PrivacyText>
          Changes to this privacy policy may be made by us from time to time. In no event will these
          changes result in the degradation of any security measures designed to protect you from
          unauthorized disclosure.
        </PrivacyText>
      </PrivacySegment>
      {/* Segment */}
      <PrivacySegment space="2rem">
        <PrivacyHeader>11. Contact Us</PrivacyHeader>
        <PrivacyText>
          To send us your questions, comments, or complaints or receiving communications from us kindly
          email us using
          {' '}
          <Bold>contact@carboncollectiblenfts.com</Bold>
        </PrivacyText>
      </PrivacySegment>
    </PrivacyWrapper>
  </PrivacyContainer>
);

export default PrivacySection;
