import React, { useState, useEffect } from 'react';
import {
  FaTwitter, FaFacebook, FaDiscord, FaInstagram, FaBars, FaYoutube,
} from 'react-icons/fa';
// import { RiVipDiamondFill } from 'react-icons/ri';
import { MdEmail } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import {
  NavbarContainer,
  Nav,
  NavLogo,
  NavMenu,
  SocialIcons,
  SocialIconLink,
  NavImg,
  NavMenuButton,
  NavMenuSetNav,
  NavMenuMint,
} from './NavbarElements';
import LogoImg from '../../Images/Hero/logo2.png';
import MenuSection from '../Menu';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleHome = async () => {
    if (location.pathname !== '/') {
      await navigate('/');
    }
    scroll.scrollToTop();
  };

  const changeNav = () => {
    if (window.scrollY >= 100) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);
  return (
    <Nav scrollNav={scrollNav}>
      <NavbarContainer>
        <MenuSection isOpen={isOpen} toggle={toggle} />
        <NavLogo onClick={toggleHome}><NavImg alt="Logo" src={LogoImg} /></NavLogo>
        <NavMenu>
          <SocialIcons>
            <SocialIconLink scrollNav={scrollNav} href="http://d.ccnfts.io/" target="_blank" aria-label="Discord"><FaDiscord /></SocialIconLink>
            <SocialIconLink scrollNav={scrollNav} href="https://twitter.com/CarbonOffsetNFT" target="_blank" aria-label="Twitter"><FaTwitter /></SocialIconLink>
            <SocialIconLink scrollNav={scrollNav} href="https://www.instagram.com/accounts/login/?next=/carbonoffsetnft/" target="_blank" aria-label="Instagram"><FaInstagram /></SocialIconLink>
            <SocialIconLink scrollNav={scrollNav} href="https://www.facebook.com/Carbon-Collectible-NFTs-103176582194223/" target="_blank" aria-label="Facebook"><FaFacebook /></SocialIconLink>
            <SocialIconLink scrollNav={scrollNav} href="https://www.youtube.com/channel/UCg8JuQK30BryQPZ6oqcrGng" target="_blank" aria-label="Youtube"><FaYoutube /></SocialIconLink>
            <SocialIconLink scrollNav={scrollNav} href="mailto:info@ccNFTs.io" target="_blank" aria-label="Email"><MdEmail /></SocialIconLink>
            {/* <SocialIconLink scrollNav={scrollNav} href="https://raritysniper.com/nft-drops-calendar" target="_blank" aria-label="Website"><RiVipDiamondFill /></SocialIconLink> */}
          </SocialIcons>
          <NavMenuMint scrollNav={scrollNav} href="https://mint.carboncollectiblenfts.com/">Mint</NavMenuMint>
          <NavMenuSetNav scrollNav={scrollNav} href="/go">SatNav App</NavMenuSetNav>
          <NavMenuButton scrollNav={scrollNav} onClick={toggle}><FaBars /></NavMenuButton>
        </NavMenu>
      </NavbarContainer>
    </Nav>
  );
};

export default Navbar;
