import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
margin-top: 1rem;
margin-bottom: 1rem;
`;

const EmptySmallDivider = () => (
  <Divider />
);

export default EmptySmallDivider;
