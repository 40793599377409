/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import {
  GoalButton,
  GoalButton2,
  GoalButtonWrapper,
  GoalContainer,
  GoalH1,
  GoalHeader,
  GoalSliderWrapper,
  GoalTextWrapper,
  GoalWrapper,
  ScrollText,
  GoalP,
} from './GoalElements';
import Card from '../../Utils/Card';
import img1 from '../../Images/Traditional/tra-1.webp';
import img2 from '../../Images/Traditional/tra-2.webp';
import img3 from '../../Images/Traditional/tra-3.webp';
import img5 from '../../Images/Traditional/tra-5.webp';
import img6 from '../../Images/Traditional/tra-6.webp';
import img7 from '../../Images/Traditional/tra-7.webp';

const TraditionalSection = () => {
  let isDown = false;
  let mainSlider;
  let startX;
  let scrollLeftd;

  const slideLeft = () => {
    const slider = document.getElementById('slider2');
    slider.scrollLeft += 500;
  };

  const slideRight = () => {
    const slider = document.getElementById('slider2');
    slider.scrollLeft -= 500;
  };

  const sliderDown = (e) => {
    isDown = true;
    startX = e.pageX - mainSlider.offsetLeft;
    scrollLeftd = mainSlider.scrollLeft;
    mainSlider.classList.add('active');
  };

  const sliderLeave = () => {
    isDown = false;
    mainSlider.classList.remove('active');
  };

  const sliderMove = (e) => {
    if (!isDown) {
      return;
    }
    e.preventDefault();
    const x = e.pageX - mainSlider.offsetLeft;
    const walk = (x - startX) * 2;
    mainSlider.scrollLeft = scrollLeftd - walk;
  };

  useEffect(() => {
    mainSlider = document.getElementById('slider2');
    mainSlider.addEventListener('mousedown', sliderDown);
    mainSlider.addEventListener('mouseleave', sliderLeave);
    mainSlider.addEventListener('mouseup', sliderLeave);
    mainSlider.addEventListener('mousemove', sliderMove);
  }, []);

  return (
    <GoalContainer>
      <GoalWrapper>
        <GoalHeader>Problem Statement</GoalHeader>
        <GoalTextWrapper>
          <GoalH1>
            Many low-income forest communities are
            {' '}
            <span style={{ color: '#33682C' }}>excluded from climate finance</span>
          </GoalH1>
          <GoalButtonWrapper>
            <GoalButton onClick={slideRight}><MdArrowBackIos style={{ marginTop: '10px', marginLeft: '10px' }} /></GoalButton>
            <GoalButton2 onClick={slideLeft}><MdArrowForwardIos style={{ marginTop: '10px', marginLeft: '2px' }} /></GoalButton2>
          </GoalButtonWrapper>
        </GoalTextWrapper>
        <ScrollText>{'<<< drag left and right >>>'}</ScrollText>
        <GoalTextWrapper style={{ paddingTop: '25px' }}>
          <GoalP>
            The current price for carbon credits is too low to attract funding
            for many climate projects in low-income forest communities.
            <br />
            <br />
            Consequently, some members of these communities resort to illegal
            deforestation to clear forest land for farming, so they can feed their families.
          </GoalP>
        </GoalTextWrapper>
      </GoalWrapper>
      <GoalSliderWrapper data-aos="fade" data-aos-once="true" data-aos-delay="100" id="slider2">
        <Card first image={img2} desc="Burning fossil fuels emits carbon dioxide into the atmosphere. Forests remove carbon dioxide from the atmosphere through photosynthesis." />
        <Card image={img3} desc="Companies can offset their carbon emissions, that they cannot eliminate, by buying carbon credits from owners of forests that remove carbon dioxide from the atmosphere." />
        <Card image={img7} desc="Forests can attract funding for climate projects if the price, and number of their certified carbon credits, offers an attractive return on project costs." />
        <Card image={img6} desc="We enhance the value, and price, of certified carbon credits by bundling them with geolocated biodiversity. This helps reduce poverty-driven deforestation by attracting funding for climate projects located in financially disadvantaged communities." />
        <Card image={img5} desc="We also help low-income forest communities get equal access to carbon credit sales, and therefore funding, by bundling certified carbon credits with geolocated biodiversity into NFTs. NFTs offer non-traditional sales channels." />
        <Card last image={img1} desc="Our initial NFT sale includes up to 20,000 hectares of biodiversity. Each hectare's biodiversity is bundled into an NFT with at least 5 certified carbon credits per year." />
      </GoalSliderWrapper>
    </GoalContainer>
  );
};

export default TraditionalSection;
