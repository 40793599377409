import styled from 'styled-components';

export const NavContainer = styled.div`

`;

export const NavWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const CoordsP = styled.p`
display: none;
`;

export const NavHeader = styled.h1`
font-size: 48px;
color: #000;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 36px;
}

@media screen and (max-width: 480px){
    font-size: 28px;
}
`;

export const NavDesc = styled.p`
font-size: 24px;
color: #101110;
margin-top: 25px;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 18px;
}

@media screen and (max-width: 480px){
    font-size: 14px;
}
`;

export const NavDesc2 = styled.p`
font-size: 18px;
color: #101110;
margin-top: 10px;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 11px;
}
`;

export const CarbonButton = styled.button`
width: fit-content;
height: fit-content;
font-size: 18px;
color: ${({ colored }) => (colored ? '#fff' : '#33682c')};
padding: 10px 25px 10px 25px;
background: ${({ colored }) => (colored ? '#33682c' : '#fff')};
border: none;
border-radius: 10px;

&:hover{
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}
`;
