import React from 'react';
import {
  FaTwitter, FaFacebook, FaDiscord, FaInstagram, FaYoutube,
} from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterCopyright,
  FooterIcon,
  FooterLogo,
  FooterSocialWrapper,
  FooterText,
  FooterTextWrapper,
  FooterWrapper,
} from './FooterElements';
import logo from '../../Images/Hero/logo2.png';

const FooterSection = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const toggleHome = async () => {
    if (location.pathname !== '/') {
      await navigate('/');
    }
    scroll.scrollToTop();
  };
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLogo onClick={() => toggleHome()} alt="logo" src={logo} />
        <FooterSocialWrapper>
          <FooterIcon href="http://d.ccnfts.io/" target="_blank" aria-label="Discord"><FaDiscord /></FooterIcon>
          <FooterIcon href="https://twitter.com/CarbonOffsetNFT" target="_blank" aria-label="Twitter"><FaTwitter /></FooterIcon>
          <FooterIcon href="https://www.instagram.com/accounts/login/?next=/carbonoffsetnft/" target="_blank" aria-label="Instagram"><FaInstagram /></FooterIcon>
          <FooterIcon href="https://www.facebook.com/Carbon-Collectible-NFTs-103176582194223/" target="_blank" aria-label="Facebook"><FaFacebook /></FooterIcon>
          <FooterIcon href="https://www.youtube.com/channel/UCg8JuQK30BryQPZ6oqcrGng" target="_blank" aria-label="Youtube"><FaYoutube /></FooterIcon>
          <FooterIcon href="mailto:info@ccNFTs.io" target="_blank" aria-label="Email"><MdEmail /></FooterIcon>
        </FooterSocialWrapper>
        <FooterTextWrapper>
          <FooterText to="/privacy_policy">Privacy Policy</FooterText>
          <FooterText to="/terms_and_conditions">Terms & Conditions</FooterText>
          <FooterText to="/cookies_policy">Cookies Policy</FooterText>
        </FooterTextWrapper>
        <FooterCopyright>
          ©
          {' '}
          {new Date().getFullYear()}
          . All rights reserved
        </FooterCopyright>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default FooterSection;
