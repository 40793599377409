/* eslint-disable max-len */
import React from 'react';
import {
  Bold,
  CookiesContainer,
  CookiesHeader,
  CookiesList,
  CookiesListElement,
  CookiesSegment,
  CookiesText,
  CookiesWrapper,
} from './cookiesElements';
import EmptySmallDivider from '../../Utils/EmptyDivider/smallDivider';

const CookiesSection = () => (
  <CookiesContainer>
    <CookiesWrapper>
      <CookiesSegment>
        {/* Segment */}
        <CookiesHeader>1. Welcome</CookiesHeader>
        <CookiesText>
          The GDPR law states that we can store cookies on your device if they are strictly necessary for
          this site&apos;s operation. For all other types of cookies, we need your permission. By using the site,
          you consent to the use of cookies. Our cookie policy explains what cookies are, how we use
          cookies, how third-parties, affiliates we may partner with may use cookies on the
          {' '}
          <Bold>Company&apos;s</Bold>
          site, your choices regarding cookies, and further information about cookies.
        </CookiesText>
        <EmptySmallDivider />
        <CookiesText>
          You can change or withdraw your consent from the cookie declaration on our site at any time.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>2. What are cookies?</CookiesHeader>
        <CookiesText>
          Cookies and similar tracking technologies, such as beacons, scripts, web beacons, and tags (which
          together we refer to as
          {' '}
          <Bold>&quot;cookies&quot;</Bold>
          ). They are small bits of text, usually stored on a user&apos;s computer
          hard drive or within a browser. They enable a site to remember information about the user&apos;s visit
          to the site. On the mobile site, we use device ids or advertising ids in a similar manner to which
          we use cookies. We and some third-party partners (as described below) use cookies on our site,
          our mobile site, and within emails or other communications, we send to you (we refer to these
          together as our
          {' '}
          <Bold>&quot;sites&quot;</Bold>
          ).
        </CookiesText>
        <EmptySmallDivider />
        <CookiesText>
          To the extent the cookies we use for these purposes lead to the processing of your personal
          information, details about how we use that personal information and your rights over that
          personal information are discussed in our privacy policy.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>3. Usage data cookies</CookiesHeader>
        <CookiesText>
          Information collected automatically through this site, which can include: the IP addresses or
          domain names of the computers utilized by the users who use this site, the URI addresses
          (uniform resource identifier), the time of the request, the method used to submit the right to the
          server, the size of the file received in response, the numerical code indicating the status of the
          server&apos;s answer (successful outcome, error, etc.), the country of origin, the features of the
          browser and the operating system utilized by the user, the various time details per visit (e.g., the
          time spent on each page within site) and the information on the path followed within site with
          particular reference to the sequence of pages visited, and other parameters about the device
          operating system or the user&apos;s it environment.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>4. Server Log Files</CookiesHeader>
        <CookiesText>
          Your Internet Protocol (“IP”) address is an identifying number that is automatically assigned to
          your computer by your Internet Service Provider (“ISP”). This number may be identified and
          logged automatically in our server log files whenever you visit the Site, along with the time(s) of
          your visit(s) and the page(s) that you visited.
          {' '}
          <Bold>The Company</Bold>
          {' '}
          may use the IP addresses of all Visitors
          to calculate Site usage levels, to help diagnose problems with the Site&apos;s servers, and to administer
          the Site. We may also use IP addresses to communicate with or to block access by Visitors who
          fail to comply with our Terms of Service. Collecting IP addresses is standard practice on the
          Internet and is carried out automatically by many websites.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>5. Pixel Tags</CookiesHeader>
        <CookiesText>
          The Site may use so-called “pixel tags,” “web beacons,” “clear GIFs” or similar means (collectively,
          “Pixel Tags”) to compile aggregate statistics about Site usage and response rates. Pixel Tags allow
          us to count users who have visited certain pages of the Site, to deliver branded services, and to
          help determine the effectiveness of promotional services. When used in HTML-formatted email
          messages, Pixel Tags can tell the sender whether and when the email has been opened.
        </CookiesText>
      </CookiesSegment>
      <CookiesSegment space="2rem">
        <CookiesHeader>6. User</CookiesHeader>
        <CookiesText>
          A user is an individual using this site who, unless otherwise specified, coincides with a data
          subject.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>7. Type of cookies</CookiesHeader>
        <CookiesText>
          We use the following types of cookies on our site:
        </CookiesText>
        <EmptySmallDivider />
        <CookiesList>
          <CookiesListElement>
            <Bold>Necessary</Bold>
            {' '}
            - these are cookies that are essential for the running of our site. Without these
            cookies, parts of our site would not function. These cookies do not track where you have
            been on the internet and do not gather information about you to be used for marketing
            purposes.
          </CookiesListElement>
          <CookiesListElement space="10px">
            <Bold>Functionality</Bold>
            {' '}
            - these cookies are used to remember your preferences on our site and to
            provide enhanced, more personal features.
          </CookiesListElement>
          <CookiesListElement space="10px">
            <Bold>Analytics</Bold>
            {' '}
            - analytical performance cookies are used to monitor our site&apos;s performance,
            for example, to determine the number of page views and the number of unique users a
            site has. Web analytics services may be designed and operated by third parties.
          </CookiesListElement>
        </CookiesList>
        <EmptySmallDivider />
        <CookiesText>
          Google will use this information to evaluate your site&apos;s use, compile reports on site activity for
          site operators, and provide other services relating to Site activity and internet usage. Google may
          also transfer this information to third parties required to do so by law or where such third parties
          process the information on Google&apos;s behalf. Google will not associate your IP address with any
          other data held by Google.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>8. Surveys related cookies</CookiesHeader>
        <CookiesText>
          From time to time, we offer user surveys and questionnaires to provide you with interesting
          insights, helpful tools, or to understand our user base more accurately. These surveys may use
          cookies to remember who has already taken part in a survey or provide accurate results after you
          change pages.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>9. Site preferences cookies</CookiesHeader>
        <CookiesText>
          To provide you with a great experience on this site, we provide the functionality to set your
          preferences for how this site runs when you use it. Remember your preferences; we need to set
          cookies so that this information can be called whenever you interact with a page that is affected
          by your preferences.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>10. Third-party cookies</CookiesHeader>
        <CookiesText>
          In addition to our cookies, we may also use various third-parties cookies to report usage statistics
          of the service, on and through the NFT Site, etc.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>11. Disabling or blocking cookies?</CookiesHeader>
        <CookiesText>
          If you&apos;d like to delete cookies or instruct your web browser to delete or refuse cookies, please
          visit your web browser&apos;s help pages. Please note, however, that if you delete cookies or refuse
          to accept them, you might not be able to use all of the features we offer, you may not be able to
          store your preferences, and some of our pages might not display correctly.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>12. Your cookie choices</CookiesHeader>
        <CookiesText>
          We provide you with choices over the use of cookies as described in this policy. When you first
          visit our site, where required by law, you will have the option to give your consent to the
          advertising and functional cookies we use or to refuse your consent to our use of those cookies.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>13. Browsers</CookiesHeader>
        <CookiesText>
          Most browsers accept cookies by default. You may change the settings to have your browser
          refuse certain cookies or notify you before accepting cookies. To do so, please follow the
          instructions provided by your browser, which are usually located within the &quot;help,&quot; &quot;tools,&quot; or
          &quot;edit&quot; facility. Some third parties also provide the ability to refuse their cookies directly by clicking
          on an opt-out link. You can also find more information about cookies, including how to see what
          cookies have been set on your device and how to manage and delete them, at
          www.allaboutcookies.org and www.youronlinechoices.eu.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>14. Do-not-track signals</CookiesHeader>
        <CookiesText>
          We currently do not employ technology that recognizes &quot;do-not-track&quot; signals from your
          browser.
        </CookiesText>
        <EmptySmallDivider />
        <CookiesText>
          Please keep in mind that, without cookies, you may not have access to certain features on our
          sites, including access to your NFTs and certain personalized content. Removing all cookies from
          your computer could also affect your subsequent visits to a specific page.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>15. Cookie policy update</CookiesHeader>
        <CookiesText>
          This cookies policy is updated as of the effective date set forth above. However, the Company
          may review this cookie notice from time to time as necessary. The terms that apply to you are
          those posted here on our site on the day you use our site. We solely advise you to print a copy
          for your records.
        </CookiesText>
        <EmptySmallDivider />
        <CookiesText>
          If you have any questions regarding our cookies policy, please contact us.
        </CookiesText>
      </CookiesSegment>
      {/* Segment */}
      <CookiesSegment space="2rem">
        <CookiesHeader>16. Contact</CookiesHeader>
        <CookiesText>
          If you have any questions about our use of cookies, please send us a message.
        </CookiesText>
      </CookiesSegment>
    </CookiesWrapper>
  </CookiesContainer>
);

export default CookiesSection;
