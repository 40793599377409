import React from 'react';
import {
  CustomLink,
  ForestContainer,
  ForestHeader,
  ForesttextWrapper,
  ForestWrapper,
  ImageTextWrapper,
  SmallImage,
  Text,
  TextHeader,
  TextHeaderBig,
  TopImage,
} from './forestElements';
import EmptySmallDivider from '../../Utils/EmptyDivider/smallDivider';
import EmptyMediumDivider from '../../Utils/EmptyDivider/mediumDivider';

// Images
import map from '../../Images/OurForest/forest1.webp';
import map2 from '../../Images/OurForest/forest2.webp';
import img1 from '../../Images/OurForest/forest3.webp';
import img2 from '../../Images/OurForest/forest4.webp';
import img3 from '../../Images/OurForest/forest5.webp';
import img4 from '../../Images/OurForest/forest6.webp';

const index = () => (
  <ForestContainer>
    <ForestWrapper>
      <ForestHeader>AVATIME TRADITIONAL AREA, VOLTA REGION, GHANA</ForestHeader>
      <TopImage alt="map" src={map} />
      <ForesttextWrapper>
        <TextHeader>Our Advantage</TextHeader>
        <Text>
          The Avatime traditional area is located about 50 kilometres (from Ho to Avatime
          Dzokpe, approx.) north of the regional capital Ho, and is close to the border with
          Togo. There are seven Avatime towns:- Avatime Amedzofe, Avatime Biakpa, Avatime
          Dzogbefeme, Avatime Dzokpe, Avatime Fume, Avatime Gbadzeme, and Avatime Vane.
        </Text>
        <EmptySmallDivider />
        <Text>
          <CustomLink href="http://www.101lasttribes.com/tribes/avatime.html" target="_blank" rel="norefferer">The Avatime</CustomLink>
          {' '}
          are of the Guan extractive tribe who live in the Ho West District
          of the Volta region of Ghana. According to oral history, they are Ahanta people
          who are said to have migrated from Mesopotamia, in the East to the Volta Region,
          through Iboland in southern Nigeria where they split from the main tribes and
          then to Ahanta in the South Western part of Ghana. They are known to love settling
          in the mountains, as a security and defense strategy.
        </Text>
        <EmptySmallDivider />
        <Text>
          Avatime people are also known as Kede’one (male Avatime (sing).
          Kedeaniba,-Kedeme’aneba (pl), or Kede’obi – Kedeme obi, (son or daughter of
          Avatime, sing,) (Kedeme’bi (pl)), or Kedemedze’e (female Avatime person, sing),
          (Kedemedze ba (pl.)). They speak Sideme, Siya or Siyase, a Kwa language which
          belongs to the larger Voltaic (Niger)-Congo ethnolinguistic group in the Volta
          region of Ghana where they are surrounded by the more populous Ewe people.
        </Text>
        <EmptySmallDivider />
        <Text>
          GEMI Mountain is the second highest mountain in Ghana and the highest mountain
          close to the equator and the centre of the world. It is located in Avatime, which
          has the highest human settlement in Ghana.
        </Text>
        <EmptySmallDivider />
        <Text>
          The Avatime Traditional Area is blessed with mountain ranges, caves, canyons,
          refreshing waterfalls and attractive forests- flora and fauna. The arable land
          is conducive to cultivating grapes, exotic fruits, vegetables, tea, potatoes and
          rice. AMU is the traditional name for Avatime Mountain Brown Rice (AMBRice), which
          is celebrated in the Annual Amu Festival (AmuFest), in the second week of November.
        </Text>
        <EmptySmallDivider />
        <Text>
          According to the Doctoral Thesis by Rebecca Defina, Sydney, Australia, most Avatime
          people are subsistence farmers, though there are generally a number in each family
          who take up other professions. Each person has several plots of land in the areas
          surrounding the villages. It is said that the main staple crops are cassava, maize,
          yam, and rice. Cocoa and coffee, interspersed with exotic fruits and flowers are also
          grown as a cash crop. The Avatime people are known for a tradition of rice cultivation
          and there are many cultural traditions tied to the farming of rice. However, in the
          last few decades these traditions have largely been lost, though in 2010 the Paramount
          Chief, Osie Adza Tekpor VII, introduced annual brown (red) rice festivals, called AmuFest,
          in an attempt to revive some of these cultural practices.
        </Text>
        <EmptySmallDivider />
        <Text>
          The Avatime people currently have a chieftaincy system modelled on the mixture of Akan and
          their own adapted chieftaincy system. Avatimes practice patrilineal system of inheritance,
          as opposed to the matrilineal system of the most Akans, in Ghana. It appears to have been
          adopted relatively recently, likely in the early 19th century. Each Avatime village has
          its own male chief (Okusie) and female chief (Onetsie). The entire Avatime area is under
          the domain of a paramount male chief (Osie) and supported by a paramount female chief
          (Onetsitsie) The village of Avatime Vane is the seat of the paramountcy and the paramount
          chiefs reside there. More traditionally, each village is divided into clans (akpɔ, pl),
          (lịkpɔ, sing) which in turn consist of smaller patrilineal groups (iku, pl), (oku, sing).
          The elder of each oku is in charge of land allocation for
          farming and housing within each group.
        </Text>
      </ForesttextWrapper>
      <EmptyMediumDivider />
      <ForesttextWrapper>
        <ForestHeader>AVATIME ELEVATION MAP DRAWN BY HARRY BUGLASS</ForestHeader>
        <TopImage alt="map" src={map2} />
      </ForesttextWrapper>
      <EmptyMediumDivider />
      <ForesttextWrapper>
        <TextHeaderBig>OUR FOREST</TextHeaderBig>
        <ImageTextWrapper>
          <ForesttextWrapper>
            <TextHeader>Forestry Areas in Africa</TextHeader>
            <Text>
              According to The Nature Conservancy, Africa has one-fifth of the planet’s
              remaining forests but is losing forestry areas faster than anywhere else in
              the world. Protecting forests is among the most cost effective natural
              defenses against climate change and in Africa will help hundreds of thousands
              of people earn a better living.
            </Text>
            <EmptySmallDivider />
            <Text>
              According to the
              {' '}
              <CustomLink href="https://www.forestcarbonpartnership.org/what-redd" target="_blank" rel="norefferer">Forest Carbon Partnership Facility</CustomLink>
              {' '}
              REDD+ stands for countries
              efforts to reduce emissions from deforestation and forest degradation, and
              foster conservation, sustainable management of forests, and enhancement of
              forest carbon stocks.
            </Text>
          </ForesttextWrapper>
          <SmallImage alt="forest" src={img1} />
        </ImageTextWrapper>
        <EmptySmallDivider />
        <Text>
          Unlike afforestation and reforestation activities, which generally cause small
          annual changes in carbon stocks over long periods of time, stemming deforestation
          causes large changes in carbon stocks over a short period of time. Most emissions
          from deforestation take place rapidly, whereas carbon removal from the atmosphere
          through afforestation and reforestation activities is a slow process.
        </Text>
        <EmptySmallDivider />
        <Text>
          In addition to mitigating climate change, stopping deforestation and forest degradation
          and supporting sustainable forest management conserves water resources and prevents
          flooding, reduces run-off, controls soil erosion, reduces river siltation, protects
          fisheries, preserves biodiversity and preserves cultures and traditions.
        </Text>
        <EmptySmallDivider />
        <Text>
          With all the services that forests provide both to humanity and the natural world,
          there is now widespread understanding of a simple yet profound fact that forests are
          more important left standing, than being razed to the ground.
        </Text>
        <EmptySmallDivider />
        <Text>
          Image by
          {' '}
          <CustomLink href="https://fonibia.com/kwadwokoto" target="_blank" rel="norefferer">kwadwokoto</CustomLink>
          {' '}
          from
          {' '}
          <CustomLink href="https://fonibia.com/" target="_blank" rel="norefferer">Fonibia.</CustomLink>
        </Text>
      </ForesttextWrapper>
      <EmptyMediumDivider />
      <ForesttextWrapper>
        <TextHeader>Forestry Areas in Avatime</TextHeader>
        <ImageTextWrapper>
          <ForesttextWrapper>
            <Text>
              According to Raphael Berchie from AfroSources.com, Amedzofe ( pronounced Amɛ-jor-pɛ)
              has an average elevation of 661 meters above sea level. This makes it the
              highest habitable town in Ghana. In fact, the town is perched at the foot of
              Mount Gemi. Moreover, there is a waterfall that is nestled within the deep
              valleys on the outskirts of Amedzofe which, unusually, occupies a lower
              elevation than the village. The stream flows through the township toward the
              waterfall. This town maintains a lower average temperature, in the lower 70s
              (20s in Celsius), than other areas in Ghana.
            </Text>
            <EmptySmallDivider />
            <Text>
              Explaining how the people of Amedzofe settled on the mountain, Daniel Kafui Agbesi,
              an indigene, stated that the people left Benin City in present-day Nigeria over
              700-800 years ago to settle in this location, due to war and social conflict in
              their previous homeland.
            </Text>
          </ForesttextWrapper>
          <SmallImage alt="forest" src={img2} />
        </ImageTextWrapper>
        <EmptySmallDivider />
        <Text>
          Image by
          {' '}
          <CustomLink href="https://fonibia.com/kwadwokoto" target="_blank" rel="norefferer">kwadwokoto</CustomLink>
          {' '}
          from
          {' '}
          <CustomLink href="https://fonibia.com/" target="_blank" rel="norefferer">Fonibia.</CustomLink>
        </Text>
      </ForesttextWrapper>
      <EmptyMediumDivider />
      <ForesttextWrapper>
        <TextHeaderBig>PROJECT NOTIFICATION TO THE FORESTRY COMMISSION</TextHeaderBig>
        <Text>
          The following letter has been validated by First Florida Securities,
          Inc. a FINRA registered Broker Dealer.
        </Text>
        <EmptySmallDivider />
        <TopImage alt="image" src={img3} />
      </ForesttextWrapper>
      <EmptyMediumDivider />
      <ForesttextWrapper>
        <TextHeaderBig>LETTER OF SUPPORT FROM THE FORESTRY COMMISSION</TextHeaderBig>
        <Text>
          The following letter has been validated by First Florida Securities,
          Inc. a FINRA registered Broker Dealer.
        </Text>
        <EmptySmallDivider />
        <TopImage alt="image" src={img4} />
      </ForesttextWrapper>
    </ForestWrapper>
  </ForestContainer>
);

export default index;
