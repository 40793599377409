/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import {
  GdrpContainer, GdrpLink, GdrpText, GdrpTextWrapper, GdrpWrapper, GdrpButton, GdrpHeader,
} from './gdrpElements';

const index = () => {
  const [accepted, setAccepted] = useState(JSON.parse(localStorage.getItem('gdrp')) || false);

  const acceptGdrp = () => {
    setAccepted((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem('gdrp', accepted);
  }, [accepted]);

  return (
    !accepted && (
    <GdrpContainer>
      <GdrpWrapper>
        <GdrpTextWrapper>
          <GdrpHeader>Cookie consent</GdrpHeader>
          <GdrpText>
            This website does not use cookies. You can learn more about cookies by reading our
            {' '}
            <GdrpLink to="/cookies_policy">Cookies Policy</GdrpLink>
          </GdrpText>
        </GdrpTextWrapper>
        <GdrpButton onClick={acceptGdrp}>Accept</GdrpButton>
      </GdrpWrapper>
    </GdrpContainer>
    )
  );
};

export default index;
