import styled from 'styled-components';

export const GoalContainer = styled.div`

`;

export const GoalWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const GoalTextWrapper = styled.div`
display: grid;
grid-template-columns: 5fr 1fr;

@media screen and (max-width: 1300px){
    grid-template-columns: 1fr;
}
`;

export const GoalHeader = styled.p`
font-size: 18px;
color: #33682C;
margin-top: 20px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const GoalH1 = styled.h1`
font-size: 56px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 42px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const GoalP = styled.p`
font-size: 18px;
color: #101110;
margin-top: 20px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const GoalButtonWrapper = styled.div`
display: flex;
justify-content: flex-end;
flex-direction: row;

@media screen and (max-width: 1300px){
    margin-top: 25px;
    justify-content: flex-start;
}

@media screen and (max-width: 768px){
    display: none;
}
`;

export const GoalButton = styled.button`
width: 50px;
height: 50px;
justify-content: center;
align-items: center;
margin-top: auto;
margin-bottom: auto;
font-size: 25px;
border: 1px solid #101110;
background: #fff;

&:hover {
    transition: 0.2s all ease-in-out;
    transform: scale(1.05);
    cursor: pointer;
}
`;

export const GoalButton2 = styled.button`
width: 50px;
height: 50px;
justify-content: center;
align-items: center;
margin-top: auto;
margin-bottom: auto;
margin-left: 10px;
font-size: 25px;
border: none;
background: #33682C;
color: #fff;

&:hover {
    transition: 0.2s all ease-in-out;
    transform: scale(1.05);
    cursor: pointer;
}
`;

export const GoalSliderWrapper = styled.div`
display: flex;
flex-direction: row;
margin-top: 50px;
gap: 5%;
overflow-y: scroll;
-ms-overflow-style: none;
scroll-behavior: smooth;
scrollbar-width: none;

&:hover {
    cursor: grab;
}

&:active {
    cursor: grabbing;
}

::-webkit-scrollbar {
    display: none;
}
`;

export const ScrollText = styled.p`
font-size: 18px;
font-weight: bold;

@media screen and (max-width: 1300px){
    margin-top: 25px;
}

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;
