import styled from 'styled-components';

export const TermsContainer = styled.div`

`;

export const TermsWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
max-width: 1100px;
margin-left: auto;
margin-right: auto;
padding: 0 25px;
`;

export const TermsSegment = styled.div`
display: flex;
flex-direction: column;
margin-top: ${({ space }) => (space)};
`;

export const TermsMainHeader = styled.h1`
color: #000;
font-size: 24px;
margin-bottom: 1rem;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 18px;
}

@media screen and (max-width: 480px){
    font-size: 16px;
}
`;

export const TermsHeader = styled.h3`
color: #000;
font-size: 24px;
margin-bottom: 2rem;

@media screen and (max-width: 768px){
    font-size: 18px;
}

@media screen and (max-width: 480px){
    font-size: 16px;
}
`;

export const TermsText = styled.p`
color: #000;
font-size: 16px;
margin-top: ${({ space }) => (space)};

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const Bold = styled.span`
font-weight: bold;
`;

export const TermsList = styled.ul`
margin: 0;  
padding-left: 20px; 
`;

export const TermsListElement = styled.li`
color: #000;
font-size: 16px;
margin-top: ${({ space }) => (space)};

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;
