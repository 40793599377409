/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import { axiosBasic } from '../api/axios';

const useAxiosBasic = () => {
  useEffect(() => {
    const requestIntercept = axiosBasic.interceptors.request.use((config) => {
      if (!config.headers.Authorization) {
        config.headers.Authorization = `Basic ${process.env.REACT_APP_BASIC_KEY}`;
      }
      return config;
    }, (error) => Promise.reject(error));

    return () => {
      axiosBasic.interceptors.request.eject(requestIntercept);
    };
  }, []);

  return axiosBasic;
};

export default useAxiosBasic;
