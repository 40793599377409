import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import ProjectSection from '../Components/ProjectPrinciples';

const ProjectPrinciplesPage = () => (
  <>
    <ScrollToTop />
    <SubHeroSection header="Project Principals" heroHeight="20rem" />
    <EmptyDivider />
    <ProjectSection />
    <EmptyDivider />
  </>
);

export default ProjectPrinciplesPage;
