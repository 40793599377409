/* eslint-disable quotes */
/* eslint-disable template-tag-spacing */
import styled from 'styled-components';

export const AboutUsContainer = styled.div`

`;

export const AboutUsWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
max-width: 1100px;
margin-left: auto;
margin-right: auto;
padding: 0 25px;
`;

export const AboutUsBrand = styled.p`
font-size: 18px;
color: #000;
text-align: center;
margin-top: 15px;
font-weight: 600;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const AboutUsHeader = styled.h1`
font-size: 24px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 20px;
}

@media screen and (max-width: 480px){
    font-size: 18px;
}
`;

export const AboutUsDesc = styled.p`
font-size: 18px;
color: #000;
margin-top: 25px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const AboutUsPart = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 2fr 1fr);
align-items: center;
grid-template-areas: ${({ imgStart }) => (imgStart ? `'col2'` `'col1'` : `'col1 col2'`)};
gap: 25px;

@media screen and (max-width: 1080px){
    grid-template-areas: ${({ imgStart }) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
}
`;

export const AboutUsImage = styled.img`
width: 100%;
max-width: 300px;
height: auto;
`;

export const Column1 = styled.div`
margin-bottom: 15px;
grid-area: col1;
margin-left: auto;
margin-right: auto;
`;

export const Column2 = styled.div`
margin-bottom: 15px;
grid-area: col2;
margin-left: auto;
margin-right: auto;
padding-left: 25px;

@media screen and (max-width: 1080px){
    margin-top: 50px;
    padding-left: 0;
}
`;

export const AboutUsBrandContainer = styled.div`
background: #F0FFE6;
padding: 50px 0;
margin-top: 100px;
`;

export const TextWrapper = styled.div`
display: flex;
flex-direction: column;
`;
