import styled from 'styled-components';

export const RoadmapContainer = styled.div`

`;

export const RoadmapWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
width: 80%;
margin-left: auto;
margin-right: auto;

@media screen and (max-width: 1400px){
    grid-template-columns: 1fr;
}
`;

export const RoadmapH1 = styled.h1`
font-size: 56px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 42px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const RoadmapP = styled.p`
font-size: 18px;
color: #101110;
margin-top: 20px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const RoadmapMainWrapper = styled.div`
display: flex;
flex-direction: column;
margin-left: auto;
margin-right: auto;
`;

export const RoadmapTextSideWrapper = styled.div`

`;

export const RoadmapElementSingle = styled.div`
display: flex;
flex-direction: row;
margin-top: 100px;
`;

export const RoadmapElementLeft = styled.div`

`;

export const RoadmapElementRight = styled.div`
margin-left: 100px;
`;

export const TimeText = styled.p`
color: ${({ active }) => (active ? '#33682C' : '#292F28')};;
`;

export const SectionH6 = styled.h6`
color: ${({ active }) => (active ? '#33682C ' : '#292F28')};
margin-top: 30px;
font-size: 32px;
font-weight: 500;

@media screen and (max-width: 768px){
    font-size: 24px;
}

@media screen and (max-width: 480px){
    font-size: 18px;
}
`;

export const RoadmapCircle = styled.div`
border: 1px solid rgba(0,0,0, 0.2);
border-radius: 50%;
background: ${({ active }) => (active ? '#33682C' : '#fff')};
width: 50px;
height: 50px;
`;

export const RoadmapCircleText = styled.p`
position: relative;
color: ${({ active }) => (active ? '#fff' : '#101110')};
text-align: center;
justify-content: center;
align-items: center;
margin-top: 10px;
`;

export const RoadmapBorder = styled.div`
position: absolute;
display: ${({ last }) => (last ? 'none' : 'flex')};;
border-left: 2px solid #33682C;
height: 12.5rem;
z-index: -1;
margin-left: 1.5rem;
`;
