import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import OurForestSection from '../Components/OurForest';

const OurForestPage = () => (
  <>
    <ScrollToTop />
    <SubHeroSection header="Our Forest" heroHeight="20rem" />
    <EmptyDivider />
    <OurForestSection />
    <EmptyDivider />
  </>
);

export default OurForestPage;
