import React from 'react';
import {
  ContributeContainer,
  ContributeH1,
  ContributeP,
  ContributeTable,
  ContributeWrapper,
  TableTd,
  TableTh,
  TableTr,
  TableThead,
} from './ContributeElements';

const ContributeSection = () => (
  <ContributeContainer>
    <ContributeWrapper>
      <ContributeH1>
        Want to
        {' '}
        <span style={{ color: '#33682C' }}>contribute</span>
        {' '}
        to the fight against global warming?
      </ContributeH1>
      <ContributeP>
        Buy one, or more, of our carbon-backed, pay-to-preserve NFTs to compensate for
        the carbon footprint of your organization, or event, or to compensate for your
        personal carbon footprint.
      </ContributeP>
      <ContributeTable>
        <TableThead>
          <TableTr>
            <TableTh scope="col">An Organization</TableTh>
            <TableTh scope="col">In-Person Conference</TableTh>
            <TableTh scope="col">NFT Release</TableTh>
            <TableTh scope="col">High-Income Countries</TableTh>
            <TableTh scope="col">Other Countries</TableTh>
          </TableTr>
        </TableThead>
        <tbody>
          <TableTr>
            <TableTd data-label="An Organization">1 NFT per knowledge worker</TableTd>
            <TableTd data-label="In-Person Conferance">1 NFT per 35 attendees per day</TableTd>
            <TableTd data-label="NFT Release">1 NFT for each 100 NFTs minted in a Collection</TableTd>
            <TableTd data-label="High Income Countries">2 to 6 NFTs per person</TableTd>
            <TableTd data-label="Other Countries">1 NFT per person</TableTd>
          </TableTr>
        </tbody>
      </ContributeTable>
    </ContributeWrapper>
  </ContributeContainer>
);

export default ContributeSection;
