import styled from 'styled-components';

export const Nav = styled.nav`
background: ${({ scrollNav }) => (scrollNav ? '#fff' : 'rgba(255,255,255,0.2)')};
backdrop-filter: blur(5px);
height: 80px;
margin-top: -80px;
display: flex;
justify-content: center;
align-items: center;
font-size: 1rem;
position: sticky;
top: 0;
z-index: 10;
transition: 0.5s all ease;

@media screen and (max-width: 960px){
    transition: 0.8s all ease;
}
`;

export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
z-index: 1;
width: 100%;
max-width: 80%;
padding-left: 20px;
padding-right: 20px;

@media screen and (max-width: 1200px){
    max-width: 100%;
}
`;

export const NavLogo = styled.a`
color: #fff;
justify-self: flex-start;
cursor: pointer;
margin-top: auto;
margin-bottom: auto;
`;

export const NavImg = styled.img`
display: flex;
width: 160px;
height: 60px;
`;

export const NavMenu = styled.ul`
display: flex;
align-items: center;
justify-content: center;
list-style: none;
text-align: center;
`;

export const SocialIcons = styled.div`
display: flex;
justify-content: space-between;
gap: 25px;
margin-top: 10px;

@media screen and (max-width: 1100px){
    display: none;
}
`;

export const SocialIconLink = styled.a`
color: ${({ scrollNav }) => (scrollNav ? '#33682C' : '#fff')};
font-size: 28px;

&:hover {
    transform: scale(1.05);
}
`;

export const NavMenuButton = styled.a`
margin-left: 40px;
color:  ${({ scrollNav }) => (scrollNav ? '#33682C' : '#fff')};
font-size: 28px;
margin-top: 10px;

@media screen and (max-width: 960px){
    margin-left: 25px;
}

&:hover {
    cursor: pointer;
    transform: scale(1.05);
}
`;

export const NavMenuSetNav = styled.a`
color: ${({ scrollNav }) => (scrollNav ? '#fff' : '#33682C')};
border-radius: 20px;
background-color: ${({ scrollNav }) => (scrollNav ? '#33682C' : '#fff')};;
margin-top: auto;
margin-bottom: auto;
margin-left: 20px;
margin-right: auto;
padding: 5px 20px;
font-weight: 500;
text-decoration: none;
font-size: 18px;
white-space: nowrap;

@media screen and (max-width: 768px){
    font-size: 14px;
    margin-left: 0;
}

@media screen and (max-width: 480px){
    font-size: 10px;
}

&:hover {
    cursor: pointer;
    transform: scale(1.05);
}
`;

export const NavMenuAboutUs = styled.a`
color: ${({ scrollNav }) => (scrollNav ? '#fff' : '#33682C')};
border-radius: 20px;
background-color: ${({ scrollNav }) => (scrollNav ? '#33682C' : '#fff')};;
margin-top: auto;
margin-bottom: auto;
margin-left: 40px;
padding: 5px 20px;
font-weight: 500;
text-decoration: none;
font-size: 18px;
white-space: nowrap;

@media screen and (max-width: 768px){
    display: none;
}

&:hover {
    cursor: pointer;
    transform: scale(1.05);
}
`;

export const NavMenuMint = styled.a`
color: ${({ scrollNav }) => (scrollNav ? '#fff' : '#33682C')};
border-radius: 20px;
background-color: ${({ scrollNav }) => (scrollNav ? '#33682C' : '#fff')};;
margin-top: auto;
margin-bottom: auto;
margin-left: 20px;
padding: 5px 20px;
font-weight: 500;
text-decoration: none;
font-size: 18px;
white-space: nowrap;

@media screen and (max-width: 768px){
    display: none;
}

&:hover {
    cursor: pointer;
    transform: scale(1.05);
}
`;
