import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.div`

`;

export const FooterWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
justify-content: center;
align-items: center;
text-align: center;
`;

export const FooterLogo = styled.img`
width: 100%;
max-width: 250px;
height: auto;

&:hover {
    cursor: pointer;
}
`;

export const FooterSocialWrapper = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
gap: 15%;
margin-top: 50px;
`;

export const FooterTextWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 2fr 1fr;
margin-top: 50px;
`;

export const FooterText = styled(Link)`
color: #101110;
font-size: 18px;
text-decoration: none;
text-align: center;

&:hover {
    transition: 0.2s all ease-in-out;
    transform: scale(1.05);
    cursor: pointer;
}

@media screen and (max-width: 768px){
    font-size: 12px;
}

@media screen and (max-width: 480px){
    font-size: 11px;
}
`;

export const FooterCopyright = styled.p`
color: #101110;
font-size: 20px;
margin-top: 25px;
margin-bottom: 10px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const FooterIcon = styled.a`
color: #33682C;
font-size: 24px;

&:hover {
    transition: 0.2s all ease-in-out;
    transform: scale(1.05);
}
`;
