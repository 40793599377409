import React from 'react';
import {
  RoadmapBorder,
  RoadmapCircle,
  RoadmapCircleText,
  RoadmapContainer,
  RoadmapElementLeft,
  RoadmapElementRight,
  RoadmapElementSingle,
  RoadmapH1,
  RoadmapMainWrapper,
  RoadmapTextSideWrapper,
  RoadmapWrapper,
  SectionH6,
  TimeText,
} from './RoadmapElements';

const RoadmapPart = ({
  active, number, date, desc, last,
}) => (
  <RoadmapElementSingle>
    <RoadmapElementLeft>
      <RoadmapCircle data-aos="fade" data-aos-once="true" data-aos-delay={`${number}00`} active={active}>
        <RoadmapCircleText active={active}>{number}</RoadmapCircleText>
      </RoadmapCircle>
      <RoadmapBorder data-aos="fade" data-aos-once="true" data-aos-delay="500" last={last} />
    </RoadmapElementLeft>
    <RoadmapElementRight>
      <TimeText data-aos="fade" data-aos-once="true" data-aos-delay={`${number}00`} active={active}>{date}</TimeText>
      <SectionH6 data-aos="fade" data-aos-once="true" data-aos-delay={`${number}00`} active={active}>{desc}</SectionH6>
    </RoadmapElementRight>
  </RoadmapElementSingle>
);

const RoadmapSection = () => (
  <RoadmapContainer id="roadmap">
    <RoadmapWrapper>
      <RoadmapTextSideWrapper>
        <RoadmapH1>
          Our
          {' '}
          <span style={{ color: '#33682C' }}>Roadmap</span>
        </RoadmapH1>
        {/* <RoadmapP>Our MVP includes the key components of a Web 3.0 value exchange</RoadmapP> */}
      </RoadmapTextSideWrapper>
      <RoadmapMainWrapper>
        <RoadmapPart number="1" date="Q1 2022" desc="Launched our free NFT" active />
        <RoadmapPart number="2" date="Q1 2023" desc="Initial Sale of our Carbon-backed, Pay-to-Preserve NFTs" />
        <RoadmapPart number="3" date="Q2 2023" desc="Launch our ReFi Social Innovation Studio" last />
      </RoadmapMainWrapper>
    </RoadmapWrapper>
  </RoadmapContainer>
);

export default RoadmapSection;
