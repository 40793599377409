import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { PlayerWrapper, VideoContainer, VideoWrapper } from './VideoElements';

const VideoSection = ({ video, specialId }) => (
  <VideoContainer id={`video-${specialId}`}>
    <VideoWrapper>
      <PlayerWrapper>
        <ReactPlayer
          width="100%"
          height="100%"
          url={video}
        />
      </PlayerWrapper>
    </VideoWrapper>
  </VideoContainer>
);

export default VideoSection;
