import React from 'react';
import {
  BlockchainContainer,
  BlockchainH1,
  BlockchainHeader,
  BlockchainImage,
  BlockchainP,
  BlockchainWrapper,
  Column1,
  Column2,
  ColumnWrapper,
  ImageWrapper,
} from './BlockchainElements';
import img1 from '../../Images/Blockchain/blockchain-1.png';

const BlockchainSection = () => {
  const variants = {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: 300 },
  };
  return (
    <BlockchainContainer style={{ paddingTop: '100px', paddingBottom: '100px' }}>
      <BlockchainWrapper>
        <ColumnWrapper>
          <Column1>
            <BlockchainHeader>Our Solution</BlockchainHeader>
            <BlockchainH1>
              We use Web3 innovations to enhance the value and price of
              {' '}
              <span style={{ color: '#33682C' }}>carbon credits</span>
            </BlockchainH1>
            <BlockchainP>
              We onboard each forest, that already has certified carbon credits, into our
              SatNav App by geolocating the forest’s boundary and its biodiversity. Our SatNav
              App then uses estimated carbon removal to assign the pre-certified carbon credits
              to each hectare of the forest. Our app also matches geolocated biodiversity to
              each hectare in the forest.
              <br />
              <br />
              Our SatNav App assigns each selected hectare in a forest to one NFT. It then
              conveys, to each NFT, at least 5 carbon credits per year plus virtual rights to
              the biodiversity in the NFT’s dedicated hectare. The NFT owner can offset their
              carbon footprint while tracking the impact of interventions that preserve their
              sponsored biodiversity. Each of our NFTs is therefore a carbon-backed,
              pay-to-preserve, biodiversity impact certificate.
              <br />
              <br />
              We pay a fee to each forest owner to cover forest management, forest security
              and their other committed co-benefits. We use 50% of the NFT’s net margin,
              above this fee, to fund a ReFi Social Innovation Studio, which finances regenerative
              entrepreneurship that multiplies, recycles and compounds NFT sponsorship.
              <br />
              <br />
              We report carbon, biodiversity and other ESG benefits as composable
              rarity icons on each geolocated NFT.
            </BlockchainP>
          </Column1>
          <Column2>
            <ImageWrapper>
              <BlockchainImage
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 1 }}
                variants={variants}
                alt="Blockchain"
                src={img1}
              />
            </ImageWrapper>
          </Column2>
        </ColumnWrapper>
      </BlockchainWrapper>
    </BlockchainContainer>
  );
};

export default BlockchainSection;
