import styled from 'styled-components';

export const InnovationContainer = styled.div`
background: #F0FFE6;
`;

export const InnovationWrapper = styled.div`
display: flex;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const ColumnWrapper = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({ imgStart }) => (imgStart ? '\'col2\''`'col1'` : '\'col1 col2\'')};

@media screen and (max-width: 1300px){
    grid-template-areas: ${({ imgStart }) => (imgStart ? '\'col1\' \'col2\'' : '\'col1 col1\' \'col2 col2\'')};
}
`;

export const Column1 = styled.div`
padding: 0 15px;
grid-area: col1;
`;

export const Column2 = styled.div`
padding: 0 15px;
grid-area: col2;
margin-left: auto;
margin-right: auto;

@media screen and (max-width: 1300px){
    margin-top: 100px;
}
`;

export const InnovationHeader = styled.p`
font-size: 18px;
color: #33682C;
margin-top: 20px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const InnovationH1 = styled.h1`
font-size: 56px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 42px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const InnovationP = styled.p`
font-size: 18px;
color: #101110;
margin-top: 20px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const InnovationImage = styled.img`
width: 100%;
max-width: 600px;
height: auto;
margin-left: auto;
margin-right: auto;
`;
