import React from 'react';
import {
  ImageTextWrapper,
  ProjectContainer,
  ProjectTextWrapper,
  ProjecttWrapper,
  TextHeader,
  Text,
  SmallImage,
  CustomLink,
} from './projectElements';
import EmptySmallDivider from '../../Utils/EmptyDivider/smallDivider';
import EmptyMediumDivider from '../../Utils/EmptyDivider/mediumDivider';

// Images
import img1 from '../../Images/Projectprinciples/project1.webp';
import img2 from '../../Images/Projectprinciples/project2.webp';
import img3 from '../../Images/Projectprinciples/project3.webp';

const index = () => (
  <ProjectContainer>
    <ProjecttWrapper>
      <ProjectTextWrapper>
        <TextHeader>Program Visionary</TextHeader>
        <ImageTextWrapper>
          <ProjectTextWrapper>
            <Text>
              <CustomLink href="https://www.ghanaweb.com/GhanaHomePage/NewsArchive/Remove-the-Togolese-tag-on-my-people-Togbui-Fiti-V-to-politicians-1005586" target="_blank" rel="norefferer">Togbui Fiti V</CustomLink>
              {' '}
              is the Paramount Chief and President of the Aflao
              Traditional Council. He is the traditional ruler of the land and performs
              traditional administrative and ceremonial functions in the area. His
              Kingdom is divided among areas within Ghana, Togo and Benin. Unfortunately,
              the land occupied by his subjects was bisected by arbitrary national
              borders that did not consider traditional boundaries when the map of
              Africa was redrawn by Europeans 1885.
            </Text>
            <EmptySmallDivider />
            <Text>
              Togbui Fiti V is proud of the history and tradition of his people.
              However, he believes that modern innovations offer a very bright future
              for his people. As a blockchain enthusiast, he believes DAOs, DeFi, Stable
              Coins, NFTs and Crypto create the opportunity to reimagine solutions to
              many of the social challenges that currently constrain the fortunes of his people.
            </Text>
            <EmptySmallDivider />
            <Text>
              Togbui Fiti V invites you to visit his traditional area for a vacation or for
              business due diligence. He says...&quot;Bring your knowledge, skills and goodwill.
              Understand our challenges and opportunities. Collaborate with our people to use
              your blockchain, and other, skills to develop sustainable solutions to our challenges
              while making a profit. We will provide any support, within our modest capabilities,
              that you need to make a profit with purpose. We invite you to do well by doing good.
              Let me know when you plan to visit us.&quot;
            </Text>
          </ProjectTextWrapper>
          <SmallImage alt="image" src={img1} />
        </ImageTextWrapper>
      </ProjectTextWrapper>
      <EmptyMediumDivider />
      <ProjectTextWrapper>
        <TextHeader>Leader of the Avatime Traditional Area</TextHeader>
        <ImageTextWrapper>
          <ProjectTextWrapper>
            <Text>
              <CustomLink href="https://www.modernghana.com/news/180699/new-osie-of-avatime-installed.html" target="_blank" rel="norefferer">Osie Adza Tekpor VII</CustomLink>
              {' '}
              is the King of the Avatime Traditional Area in the Ho
              West District of the Volta Region in Ghana, West Africa. His influence transcends
              his traditional area through his involvement in matters of the Volta Regional
              House of chiefs. He also plays an active role in the National development discourse.
              He is the chairman of the Congress of Traditional leaders in the Ho West
              District/Constituency. It was through his initiatives, together with the Member
              of Parliament, that the Ho west was carved out of the Ho municipality and became
              an autonomous district. He has chaired several committees and functions across
              the length and breadth of Ghana.
            </Text>
          </ProjectTextWrapper>
          <SmallImage alt="image" src={img2} />
        </ImageTextWrapper>
      </ProjectTextWrapper>
      <EmptyMediumDivider />
      <ProjectTextWrapper>
        <TextHeader>Program Manager</TextHeader>
        <ImageTextWrapper>
          <ProjectTextWrapper>
            <Text>
              Mark Lawrence has a B.S.C. from London University and a MBA in International
              Business from University of Southern California. He has more than 20 years of
              program management experience. During the 2018 Global Impact Investors Network
              conference in Paris, Mr. Lawrence showcased a Theory of Change to move emerging
              economies beyond aid through entrepreneurship. His strategy to achieve this vision
              includes leveraging &quot;zero cost&quot; DeFi infrastructure to drive social
              innovation. His implementation method is Crowd Bootstrap&lsquo;s
              &quot;Creator DAO&quot;, a social innovation studio that is like a business
              accelerator. However, it receives a list of social challenges, from disadvantaged
              communities, then motivates a creator community to monetize the advantages of the
              disadvantaged by reimagining solutions, to root causes, that are profitable and
              sustainable. Mr. Lawrence received 5 patents from the USPTO within 12 months of
              December 2020.
            </Text>
          </ProjectTextWrapper>
          <SmallImage alt="image" src={img3} />
        </ImageTextWrapper>
      </ProjectTextWrapper>
    </ProjecttWrapper>
  </ProjectContainer>
);

export default index;
