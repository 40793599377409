import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import SearchSection from '../Components/Search';
import SetnavAnimSection from '../Utils/SetnavAnim';

const SearchPage = () => (
  <>
    <SetnavAnimSection />
    <ScrollToTop />
    <SubHeroSection header="Provenance and Transparency for Biodiversity Impact Certificates" heroHeight="20rem" />
    <EmptyDivider />
    <SearchSection />
    <EmptyDivider />
  </>
);

export default SearchPage;
