/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import {
  GoalButton,
  GoalButton2,
  GoalButtonWrapper,
  GoalContainer,
  GoalH1,
  GoalHeader,
  GoalSliderWrapper,
  GoalTextWrapper,
  GoalWrapper,
  ScrollText,
} from './GoalElements';
import Card from '../../Utils/Card';
import img1 from '../../Images/Provenance/pro-1.webp';
import img2 from '../../Images/Provenance/pro-2.webp';
import img3 from '../../Images/Provenance/pro-3.webp';
import img4 from '../../Images/Provenance/pro-4.webp';

const ProvenanceSection = () => {
  let isDown = false;
  let mainSlider;
  let startX;
  let scrollLeftd;

  const slideLeft = () => {
    const slider = document.getElementById('slider3');
    slider.scrollLeft += 500;
  };

  const slideRight = () => {
    const slider = document.getElementById('slider3');
    slider.scrollLeft -= 500;
  };

  const sliderDown = (e) => {
    isDown = true;
    startX = e.pageX - mainSlider.offsetLeft;
    scrollLeftd = mainSlider.scrollLeft;
    mainSlider.classList.add('active');
  };

  const sliderLeave = () => {
    isDown = false;
    mainSlider.classList.remove('active');
  };

  const sliderMove = (e) => {
    if (!isDown) {
      return;
    }
    e.preventDefault();
    const x = e.pageX - mainSlider.offsetLeft;
    const walk = (x - startX) * 2;
    mainSlider.scrollLeft = scrollLeftd - walk;
  };

  useEffect(() => {
    mainSlider = document.getElementById('slider3');
    mainSlider.addEventListener('mousedown', sliderDown);
    mainSlider.addEventListener('mouseleave', sliderLeave);
    mainSlider.addEventListener('mouseup', sliderLeave);
    mainSlider.addEventListener('mousemove', sliderMove);
  }, []);

  return (
    <GoalContainer id="provenance">
      <GoalWrapper>
        <GoalHeader>Our SatNav App</GoalHeader>
        <GoalTextWrapper>
          <GoalH1>
            Pay-to-Preserve, Carbon Removal
            {' '}
            <span style={{ color: '#33682C' }}>App</span>
          </GoalH1>
          <GoalButtonWrapper>
            <GoalButton onClick={slideRight}><MdArrowBackIos style={{ marginTop: '10px', marginLeft: '10px' }} /></GoalButton>
            <GoalButton2 onClick={slideLeft}><MdArrowForwardIos style={{ marginTop: '10px', marginLeft: '2px' }} /></GoalButton2>
          </GoalButtonWrapper>
        </GoalTextWrapper>
        <ScrollText>{'<<< drag left and right >>>'}</ScrollText>
      </GoalWrapper>
      <GoalSliderWrapper data-aos="fade" data-aos-once="true" data-aos-delay="100" id="slider3">
        <Card first image={img1} header="Scarcity" desc="This initial NFT sale includes rights to up to 20,000 hectares of biodiversity plus at least 5 carbon credits per year per hectare." />
        <Card image={img3} header="Provenance" desc="Our SatNav App assigns, to each NFT, rights to one dedicated hectare of forestry and biodiversity." />
        <Card image={img2} header="Transparency" desc="Our SatNav App uses satellite imagery to view the tree cover, and geolocated biodiversity, associated each NFT’s dedicated hectare." />
        <Card last image={img4} header="Rarity" desc="Our SatNav App composes ESG icons, as physical rarity, on each NFT to represent the biodiversity located in that NFT’s assigned hectare." />
      </GoalSliderWrapper>
    </GoalContainer>
  );
};

export default ProvenanceSection;
