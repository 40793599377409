import styled from 'styled-components';

export const CardWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
margin-left: ${({ first }) => (first ? '10%' : '0')};
margin-right: ${({ last }) => (last ? '10%' : '0')};
`;

export const CardImage = styled.img`
width: 100%;
max-width: 300px;
height: auto;
border-radius: 25px;

@media screen and (max-width: 768px){
    max-width: 250px;
}

@media screen and (max-width: 480px){
    max-width: 200px;
}
`;

export const CardHeader = styled.h4`
color: #33682C;
font-size: 36px;
margin-top: 25px;

@media screen and (max-width: 768px){
    font-size: 28px;
}

@media screen and (max-width: 480px){
    font-size: 24px;
}
`;

export const CardDesc = styled.p`
color: #101110;
font-size: 18px;
width: 350px;
margin-top: 25px;
max-width: 300px;

@media screen and (max-width: 768px){
    font-size: 14px;
    max-width: 250px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
    max-width: 200px;
}
`;
