import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import NotFoundSection from '../Components/NotFound';

const NotFoundPage = () => (
  <>
    <ScrollToTop />
    <SubHeroSection heroHeight="5rem" />
    <EmptyDivider />
    <NotFoundSection />
    <EmptyDivider />
  </>
);

export default NotFoundPage;
