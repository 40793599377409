import React from 'react';
import {
  HeroContainer,
  HeroBg,
  ImageBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  HeroButton,
  HeroButtonText,
  TimerText,
} from './HeroElements';
import Img from '../../Images/Hero/hero-bg.png';

const HeroSection = () => (
  <HeroContainer>
    <HeroBg>
      <ImageBg autoPlay loop muted src={Img} type="video/webm" />
    </HeroBg>
    <HeroContent>
      <HeroH1>
        Buy a
        Carbon
        <br />
        Collectible
        NFT
      </HeroH1>
      <HeroP>
        and you receive 5 certified carbon credits per year bundled with sponsorship
        rights to 1 dedicated hectare of biodiversity (1 hectare is 2.47 acres) but no
        physical rights to the land or biodiversity.
      </HeroP>
      <TimerText>
        <a style={{ color: '#fff' }} href="/waitlist">Join our wait list</a>
        {' '}
        for the private pre-sale.
      </TimerText>
      <HeroBtnWrapper>
        <a href="https://mint.carboncollectiblenfts.com/"><HeroButton><HeroButtonText>Private Sale Mint</HeroButtonText></HeroButton></a>
      </HeroBtnWrapper>
    </HeroContent>
  </HeroContainer>
);

export default HeroSection;
