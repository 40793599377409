import styled from 'styled-components';

export const ForestContainer = styled.div`

`;

export const ForestWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
max-width: 1100px;
margin-left: auto;
margin-right: auto;
padding: 0 25px;
`;

export const ForestHeader = styled.h1`
font-size: 28px;
color: #000;
text-align: center;
margin-bottom: 25px;

@media screen and (max-width: 768px){
    font-size: 24px;
}

@media screen and (max-width: 480px){
    font-size: 18px;
}
`;

export const ForesttextWrapper = styled.div`
display: flex;
flex-direction: column;
`;

export const TextHeader = styled.h2`
font-size: 24px;
color: #33682C;
margin-top: 25px;
margin-bottom: 15px;

@media screen and (max-width: 768px){
    font-size: 20px;
}

@media screen and (max-width: 480px){
    font-size: 16px;
}
`;

export const TextHeaderBig = styled.h2`
font-size: 28px;
color: #33682C;
margin-top: 25px;
margin-bottom: 15px;

@media screen and (max-width: 768px){
    font-size: 24px;
}

@media screen and (max-width: 480px){
    font-size: 20px;
}
`;

export const Text = styled.p`
font-size: 18px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 16px;
}

@media screen and (max-width: 480px){
    font-size: 14px;
}
`;

export const TopImage = styled.img`
width: 100%;
height: auto;
margin-top: 25px;
margin-bottom: 25px;
border-radius: 10px;
`;

export const CustomLink = styled.a`
color: #33682C;
`;

export const ImageTextWrapper = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 50px;

@media screen and (max-width: 768px){
    grid-template-columns: repeat(1, 1fr);
}
`;

export const SmallImage = styled.img`
width: 100%;
height: auto;
margin-top: auto;
margin-bottom: auto;
border-radius: 10px;
`;
