import React from 'react';
import { useParams } from 'react-router-dom';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import SetNavSection from '../Components/SetNav';

const SetNavPage = () => {
  const { hectare, nftID } = useParams();
  return (
    <>
      <ScrollToTop />
      <SubHeroSection heroHeight="5rem" />
      <SetNavSection hectare={hectare} nftID={nftID} />
      <EmptyDivider />
    </>
  );
};
export default SetNavPage;
