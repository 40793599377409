import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import AboutUsSection from '../Components/AboutUs';

const AboutUsPage = () => (
  <>
    <ScrollToTop />
    <SubHeroSection header="About Us" heroHeight="20rem" />
    <EmptyDivider />
    <AboutUsSection />
    <EmptyDivider />
  </>
);

export default AboutUsPage;
