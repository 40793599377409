import React from 'react';
import {
  CardHeader,
  CardImage,
  CardText,
  RightsCard,
  RightsContainer,
  RightsGridWrapper,
  RightsHeader,
  RightsWrapper,
  CustomLink,
} from './rightsElements';

// Images
import img2 from '../../Images/YourRights/2.webp';
import img3 from '../../Images/YourRights/3.webp';
import img4 from '../../Images/YourRights/4.webp';
import img5 from '../../Images/YourRights/5.webp';
import img6 from '../../Images/YourRights/6.webp';

const index = () => (
  <RightsContainer>
    <RightsWrapper>
      <RightsHeader>THE RIGHTS CONVEYED BY YOUR CARBON COLLECTIBLE NFT</RightsHeader>
      <RightsGridWrapper>
        <RightsCard>
          <CardImage alt="cardImage" src={img2} />
          <CardHeader>Virtual Rights</CardHeader>
          <CardText>
            Your Carbon Collectible NFT conveys, to you, virtual rights to 1 hectare of
            biodiversity, and 5 carbon credits per year, for the duration of your NFT. You
            can use these rights for gaming, metaverse and other purposes that are not
            immoral and do not create harm.
          </CardText>
        </RightsCard>
        <RightsCard>
          <CardImage alt="cardImage" src={img3} />
          <CardHeader>Physical Rights</CardHeader>
          <CardText>
            Your Carbon Collectible NFT does not convey to you any physical rights to
            the trees, land or any other physical feature associated with the forest.
            {' '}
            <text style={{ fontWeight: 'bold' }}>Your NFT is not an investment contract</text>
            {' '}
            and does not convey any ownership rights to the forest or any project or business.
          </CardText>
        </RightsCard>
        <RightsCard>
          <CardImage alt="cardImage" src={img4} />
          <CardHeader>Copyright</CardHeader>
          <CardText>
            We grant you rights to the artwork for your Carbon Collectible NFT under the
            creative commons license BY-SA 4.0.
            <br />
            <br />
            ©
            {' '}
            {new Date().getFullYear()}
            . This work is licensed under a
            <CustomLink href="https://creativecommons.org/licenses/by-sa/4.0/" target="_blank" rel="noopener noreferrer"> CC BY-SA 4.0 license.</CustomLink>
          </CardText>
        </RightsCard>
        <RightsCard>
          <CardImage alt="cardImage" src={img5} />
          <CardHeader>Other Rights</CardHeader>
          <CardText>
            Please contact us to discuss any other rights that are not expressly
            included or excluded herein.
          </CardText>
        </RightsCard>
        <RightsCard>
          <CardImage alt="cardImage" src={img6} />
          <CardHeader>Restrictions</CardHeader>
          <CardText>
            You are not permitted to separate any rights from your NFT. If you transfer
            ownership of your NFT then you are also transferring all associated rights
            and restrictions exactly as described herein.
          </CardText>
        </RightsCard>
      </RightsGridWrapper>
    </RightsWrapper>
  </RightsContainer>
);

export default index;
