/* eslint-disable no-sequences */
import React from 'react';
import { CgClose } from 'react-icons/cg';
import { MdEmail } from 'react-icons/md';
import {
  FaTwitter, FaFacebook, FaDiscord, FaInstagram,
} from 'react-icons/fa';
import { scroller } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CloseButton,
  CloseButtonWrapper,
  Item,
  MenuContainer,
  MenuItems,
  MenuWrapper,
  SocialIcon,
  SocialIconsWrapper,
  Divider,
} from './MenuElements';

const MenuSection = ({ isOpen, toggle }) => {
  const location = useLocation();
  const navigate = useNavigate();

  function openInNewTab(url) {
    window.open(url, '_blank').focus();
  }

  const scrollTarget = (target) => scroller.scrollTo(
    target,
    {
      smooth: true, duration: 1000, offset: -120,
    },
  );

  const scrollToPage = async (target) => {
    if (location.pathname !== '/') {
      await navigate('/');
    }
    scrollTarget(target);
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '100%' },
  };
  return (
    <MenuContainer
      variants={variants}
      animate={isOpen ? 'open' : 'closed'}
      transition={{ duration: 0.4 }}
      initial="closed"
    >
      <MenuWrapper>
        <CloseButtonWrapper>
          <CloseButton onClick={toggle}><CgClose style={{ marginTop: '5px' }} color="#33682C" size="30px" /></CloseButton>
        </CloseButtonWrapper>
        <MenuItems>
          {/* Same page links */}
          <Item
            onClick={() => (scrollToPage('project'), toggle())}
          >
            Our Project
          </Item>
          <Item
            onClick={() => (scrollToPage('video-1'), toggle())}
          >
            Video
          </Item>
          <Item
            onClick={() => (scrollToPage('provenance'), toggle())}
          >
            SatNav App
          </Item>
          <Item
            onClick={() => (scrollToPage('innovation'), toggle())}
          >
            Social Innovation Studio
          </Item>
          <Item
            onClick={() => (scrollToPage('roadmap'), toggle())}
          >
            Roadmap
          </Item>
          <Divider />
          {/* Diffrent page links */}
          <Item
            onClick={() => (navigate('/about'), toggle())}
          >
            About Us
          </Item>
          <Item
            onClick={() => (navigate('/go'), toggle())}
          >
            SatNav App
          </Item>
          <Item
            onClick={() => (openInNewTab('https://mint.carboncollectiblenfts.com/'), toggle())}
          >
            Mint
          </Item>
          {/* <Item
            onClick={() => (navigate('/project_principals'), toggle())}
          >
            Project Principals
          </Item> */}
          {/* <Item
            onClick={() => (navigate('/our_forest'), toggle())}
          >
            Our Forest
          </Item> */}
          <Item
            onClick={() => (navigate('/your_rights'), toggle())}
          >
            Your Rights
          </Item>
          <Item
            onClick={() => (navigate('/waitlist'), toggle())}
          >
            Apply to Waitlist
          </Item>
          <SocialIconsWrapper>
            <SocialIcon href="https://discord.com/invite/8YYA653kmp" target="_blank" aria-label="Discord"><FaDiscord /></SocialIcon>
            <SocialIcon href="https://twitter.com/CarbonOffsetNFT" target="_blank" aria-label="Twitter"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://www.facebook.com/Carbon-Collectible-NFTs-103176582194223/" target="_blank" aria-label="Facebook"><FaFacebook /></SocialIcon>
            <SocialIcon href="https://www.instagram.com/accounts/login/?next=/carbonoffsetnft/" target="_blank" aria-label="Instagram"><FaInstagram /></SocialIcon>
            <SocialIcon href="mailto:info@ccNFTs.io" target="_blank" aria-label="Email"><MdEmail /></SocialIcon>
          </SocialIconsWrapper>
        </MenuItems>
      </MenuWrapper>
    </MenuContainer>
  );
};

export default MenuSection;
