import styled from 'styled-components';

export const HypothesisContainer = styled.div`

`;

export const HypothesisWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
margin-left: auto;
margin-right: auto;
`;

export const HypothesisH1 = styled.h1`
font-size: 56px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 42px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const HypothesisP = styled.p`
font-size: 18px;
color: #101110;
margin-top: 20px;

@media screen and (max-width: 768px){
    font-size: 14px;
}

@media screen and (max-width: 480px){
    font-size: 12px;
}
`;

export const HypothesisImage = styled.img`
margin-top: 50px;
width: 100%;
height: auto;
margin-left: auto;
margin-right: auto;
border-radius: 20px;
`;
