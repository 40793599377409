import { Routes, Route } from 'react-router-dom';

// Pages
import Homepage from './Pages';
import WhitelistPage from './Pages/WhitelistPage';
import SearchPage from './Pages/SearchPage';
import SetNavPage from './Pages/SetNavPage';
import AboutUsPage from './Pages/AboutUsPage';
import NotFoundPage from './Pages/NotFoundPage';
import PrivacyPolicyPage from './Pages/privacyPolicyPage';
import CookiesPolicyPage from './Pages/cookiesPolicyPage';
import TermsAndConditions from './Pages/termsAndConditionsPage';
import YourRightsPage from './Pages/RightsPage';
import ProjectPrinciplesPage from './Pages/ProjectPrinciplesPage';
import OurForestPage from './Pages/OurForestPage';

const PageHandler = () => (
  <Routes>
    <Route path="/" element={<Homepage />} />
    <Route path="/waitlist" element={<WhitelistPage />} />
    <Route path="/go" element={<SearchPage />} />
    <Route path="/my_hectare/:hectare/:nftID" element={<SetNavPage />} />
    <Route path="/about" element={<AboutUsPage />} />
    <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
    <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
    <Route path="/cookies_policy" element={<CookiesPolicyPage />} />
    <Route path="/our_forest" element={<OurForestPage />} />
    <Route path="/project_principals" element={<ProjectPrinciplesPage />} />
    <Route path="/your_rights" element={<YourRightsPage />} />
    <Route path="*" element={<NotFoundPage />} />
  </Routes>
);

export default PageHandler;
