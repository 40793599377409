import styled from 'styled-components';

export const CollabContaiber = styled.div`

`;

export const CollabWrapper = styled.div`
display: flex;
flex-direction: column;
width: 80%;
height: 100%;
margin-left: auto;
margin-right: auto;
`;

export const CollabLogoWrapper = styled.div`
display: grid;
grid-template-columns: repeat(4, 1fr); 
margin-top: 25px;
justify-content: center;
align-items: center;
height: 100%;
grid-row-gap: 500px;
gap: 50px;


@media screen and (max-width: 768px){
    transition: 0.2s all ease-in-out;
    grid-template-columns: repeat(2, 1fr); 
    gap: 100px;
}
`;

export const CollabH1 = styled.h1`
font-size: 56px;
color: #000;

@media screen and (max-width: 768px){
    font-size: 42px;
}

@media screen and (max-width: 480px){
    font-size: 32px;
}
`;

export const CollabLogo = styled.img`
width: 100%;
max-width: 200px;
height: auto;
margin: auto;
border-radius: 10px;
`;
