import React from 'react';
import styled from 'styled-components';

const Divider = styled.div`
margin-top: 10rem;
margin-bottom: 10rem;

@media screen and (max-width: 768px) {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
`;

const EmptyDivider = () => (
  <Divider />
);

export default EmptyDivider;
