import React from 'react';
import {
  VotanomicsContainer,
  VotanomicsH1,
  VotanomicsImage,
  VotanomicsP,
  VotanomicsWrapper,
} from './VotanomicsElements';
import img1 from '../../Images/Votanomics/votanomics-1.png';

const VotanomicsSection = () => (
  <VotanomicsContainer>
    <VotanomicsWrapper>
      <VotanomicsH1>
        Our
        {' '}
        <span style={{ color: '#33682C' }}>Votanomics</span>
      </VotanomicsH1>
      <VotanomicsP>
        We allow our community to vote on selected decisions.
      </VotanomicsP>
      <VotanomicsImage data-aos="fade" data-aos-once="true" data-aos-delay="100" alt="votanomcis" src={img1} />
    </VotanomicsWrapper>
  </VotanomicsContainer>
);

export default VotanomicsSection;
