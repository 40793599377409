import React from 'react';
import {
  CollabContaiber,
  CollabH1,
  CollabLogo,
  CollabLogoWrapper,
  CollabWrapper,
} from './CollabElements';
import img1 from '../../Images/Collab/collab-1.png';
import img2 from '../../Images/Collab/collab-2.png';
import img3 from '../../Images/Collab/collab-3.png';
import img5 from '../../Images/Collab/collab-5.png';
import img7 from '../../Images/Collab/collab-7.svg';
import img9 from '../../Images/Collab/collab-9.png';
import img13 from '../../Images/Collab/collab-13.svg';

const CollabSection = () => (
  <CollabContaiber>
    <CollabWrapper>
      <CollabH1>
        Collabor
        <span style={{ color: '#33682C' }}>ations</span>
      </CollabH1>
      <CollabLogoWrapper>
        <CollabLogo data-aos="fade-up" data-aos-once="true" data-aos-delay="200" alt="logo" src={img9} />
        <CollabLogo data-aos="fade-up" data-aos-once="true" data-aos-delay="300" alt="logo" src={img1} />
        <CollabLogo data-aos="fade-down" data-aos-once="true" data-aos-delay="400" alt="logo" src={img2} />
        <CollabLogo data-aos="fade-up" data-aos-once="true" data-aos-delay="500" alt="logo" src={img3} />
        <CollabLogo data-aos="fade-up" data-aos-once="true" data-aos-delay="700" alt="logo" src={img7} />
        <CollabLogo data-aos="fade-down" data-aos-once="true" data-aos-delay="800" alt="logo" src={img5} />
        <CollabLogo data-aos="fade-up" data-aos-once="true" data-aos-delay="1000" alt="logo" src={img13} />
      </CollabLogoWrapper>
    </CollabWrapper>
  </CollabContaiber>
);

export default CollabSection;
