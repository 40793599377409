/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import useAxiosBasic from '../../hooks/useAxiosBasic';
import {
  Newsbox,
  NewsContainer,
  NewsForm,
  NewsHeader,
  NewsInput,
  NewsSubmitButton,
  NewsButtonText,
  NewsWrapper,
  FeedbackText,
  NewsText,
} from './newsletterElements';

// REGEX
const EMAIL_REGEX = /^[a-z0-9]+@[a-z]+\.[a-z]{2,3}$/;

const index = () => {
  const axiosBasic = useAxiosBasic();

  const [email, setEmail] = useState('');

  const [feedback, setFeedback] = useState('');
  const [sending, setSending] = useState(false);

  const [recaptchaValue, setRecaptchaValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!recaptchaValue) {
    //   setFeedback('Please verify ReCAPTCHA');
    //   return;
    // }

    // Security
    const v1 = EMAIL_REGEX.test(email);
    if (!v1) {
      setFeedback('Invalid email');
      return;
    }

    setFeedback('Submitting...');
    setSending(true);

    try {
      axiosBasic.post(
        '/subNews',
        JSON.stringify({
          responseKey: recaptchaValue,
          email,
        }),
      ).then((res) => {
        if (res.data.status === 'success') {
          setFeedback(res.data?.message);
        } else {
          setFeedback(res.data?.message);
        }
        setSending(false);
      }).catch((res) => {
        if (res.response?.data) setFeedback(res.response.data.message);
        else if (res?.data) setFeedback(res.data?.message);
        else setFeedback('No server response');
        setSending(false);
      });
    } catch (err) {
      console.log(err);
      setFeedback('Something went wrong. Please try again later.');
      setSending(false);
    }
  };

  const recaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  // Error handling
  useEffect(() => {
    setFeedback('');
  }, [email, recaptchaValue]);

  return (
    <NewsContainer>
      <NewsWrapper>
        <Newsbox>
          <NewsForm onSubmit={handleSubmit}>
            <NewsHeader>Make an Impact</NewsHeader>
            <NewsText>Enter your email address below so we can send you key updates</NewsText>
            <NewsInput
              autoComplete="off"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder=" example@provider.com"
              type="email"
              name="email"
            />
            <ReCAPTCHA
              style={{ marginTop: '25px', marginLeft: 'auto', marginRight: 'auto' }}
              sitekey={process.env.REACT_APP_SITE_KEY}
              size={window.innerWidth > 768 ? 'normal' : 'compact'}
              theme="light"
              onChange={recaptchaChange}
            />
            <FeedbackText>{feedback}</FeedbackText>
            <NewsSubmitButton disabled={sending} type="submit">
              <NewsButtonText>{sending ? 'Busy' : 'Subscribe'}</NewsButtonText>
            </NewsSubmitButton>
          </NewsForm>
        </Newsbox>
      </NewsWrapper>
    </NewsContainer>
  );
};

export default index;
