import styled from 'styled-components';

export const HeroContainer = styled.div`
background: #0c0c0c;
display: flex;
justify-content: center;
align-items: center;
padding: 0 30px;
min-height: ${({ heroHeight }) => (heroHeight)};
position: relative;
z-index: 1;
box-shadow: 0 0 10px 15px rgba(0,0,0,0.2);
`;

export const HeroBg = styled.div`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
height: 100%;
width: 100%;
overflow: hidden;
`;

export const ImageBg = styled.img`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
background: #232a34;
`;

export const HeroContent = styled.div`
display: flex;
z-index: 3;
max-width: 100%;
flex-direction: column;
align-items: center;
margin-top: 2%;

@media screen and (max-width: 768px){
    bottom: auto;
    left: auto;
    text-align: center;
    align-items: center;
}
`;

export const HeroH1 = styled.h1`
color: #fff;
font-size: 48px;
line-height: 1.4;
font-weight: bold;
text-align: center;

@media screen and (max-width: 768px){
    font-size: 28px;
}

@media screen and (max-width: 480px){
    font-size: 18px;
}
`;

export const HeroP = styled.p`
margin-top: 20px;
margin-bottom: auto;
color: #fff;
font-size: 18px;
max-width: 600px;

@media screen and (max-width: 768px){
    font-size: 14px;
    max-width: 80%;
}

@media screen and (max-width: 480px){
    font-size: 12px;
    max-width: 80%;
}
`;

export const HeroBtnWrapper = styled.div`
margin-top: 40px;
display: flex;
flex-direction: row;
`;

export const HeroButtonText = styled.p`
color: #fff;
font-size: 18px;
padding-left: 80px;
padding-top: 20px;
padding-right: 80px;
padding-bottom: 20px;
`;

export const HeroButton = styled.button`
border: 1px solid #fff;
background: rgba(255,255,255,0.2);

&:hover {
transition: 0.5s all ease;
transform: scale(1.02);
background: rgba(255,255,255,0.4);
cursor: pointer;
}

&:hover ${HeroButtonText}{
transition: 1s all ease;
transform: scaleX(1.05);
}
`;
