import React from 'react';
import {
  HypothesisContainer, HypothesisH1, HypothesisImage, HypothesisP, HypothesisWrapper,
} from './HypothesisElements';
import img1 from '../../Images/Hypothesis/hypothesis-1.jpg';

const HypothesisSection = () => (
  <HypothesisContainer>
    <HypothesisWrapper>
      <HypothesisH1>
        Our
        <span style={{ color: '#33682C' }}> Hypothesis</span>
      </HypothesisH1>
      <HypothesisP>
        IF we increase the value of each forest and its biodiversity to its local community,
        {' '}
        <br />
        THEN that community will conserve existing trees,
        plant new trees and preserve the local biodiversity.
      </HypothesisP>
      <HypothesisImage data-aos="fade" data-aos-once="true" data-aos-delay="100" alt="hypothesis" src={img1} />
    </HypothesisWrapper>
  </HypothesisContainer>
);

export default HypothesisSection;
