import React from 'react';
import {
  SetnavAnimContainer,
  SetnavWrapper,
  SetnavLogo,
} from './setnavAnimElements';
import logo from '../../Images/Setnav/setnav.png';

const SetnavAnimSection = () => {
  const variants = {
    anim: {
      opacity: 0,
      transitionEnd: {
        display: 'none',
      },
    },
  };
  return (
    <SetnavAnimContainer
      variants={variants}
      animate="anim"
      transition={{
        duration: 1,
        delay: 1,
      }}
    >
      <SetnavWrapper>
        <SetnavLogo src={logo} alt="Setnav Logo" />
      </SetnavWrapper>
    </SetnavAnimContainer>
  );
};

export default SetnavAnimSection;
