import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import WhitelistSection from '../Components/Whitelist';

const WhitelistPage = () => (
  <>
    <ScrollToTop />
    <SubHeroSection header="JOIN OUR PRE-MINT WAITLIST" heroHeight="20rem" />
    <EmptyDivider />
    <WhitelistSection />
    <EmptyDivider />
  </>
);

export default WhitelistPage;
