import React from 'react';
import {
  HeroContainer,
  HeroBg,
  ImageBg,
  HeroContent,
  HeroH1,
} from './HeroElements';
import Img from '../../Images/Hero/hero-bg.png';

const SubHeroSection = ({ header, heroHeight }) => (
  <HeroContainer heroHeight={heroHeight}>
    <HeroBg>
      <ImageBg src={Img} />
    </HeroBg>
    <HeroContent>
      <HeroH1>
        {header}
      </HeroH1>
    </HeroContent>
  </HeroContainer>
);

export default SubHeroSection;
