import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SetnavAnimContainer = styled(motion.div)`
position: fixed;
width: 100%;
height: 100%;
background: #fff;
z-index: 10;
`;

export const SetnavWrapper = styled.div`
margin-left: auto;
margin-right: auto;
`;

export const SetnavLogo = styled.img`
position: absolute;
max-width: 600px;
width: 80%;
height: auto;
transform: translate(-50%, -50%);
top: 50%;
left: 50%;
`;
