import './App.css';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from 'react-scroll-to-top';

// Components
import Navbar from './Components/Navbar';
import FooterSection from './Components/Footer';
import PageHandler from './PageHandler';
import Gdrp from './Utils/Gdrp';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <ScrollToTop smooth color="green" />
      <Gdrp />
      <Navbar />
      <PageHandler />
      <FooterSection />
    </>
  );
}

export default App;
