// Components
import EmptyDivider from '../Utils/EmptyDivider';
import HeroSection from '../Components/Hero';
import CollabSection from '../Components/Collabrations';
import VideoSection from '../Components/Video';
import HypothesisSection from '../Components/Hypothesis';
import BlockchainSection from '../Components/Blockchain';
// import NftsSection from '../Components/Nfts';
import InnovationSection from '../Components/Innovation';
import VotanomicsSection from '../Components/Votanomics';
import RoadmapSection from '../Components/Roadmap';
import DownloadSection from '../Components/Downloads';
import GoalSection from '../Components/Goal';
import TraditionalSection from '../Components/Traditional';
import ProvenanceSection from '../Components/Provenance';
// import HowToSection from '../Components/HowTo';
import ScrollToTop from '../Utils/ScrollToTop';
import ContributeSection from '../Components/Contribute';
import NewsletterSection from '../Components/Newsletter';

const Homepage = () => (
  <>
    <ScrollToTop />
    <HeroSection />
    {/* <HowToSection /> */}
    <EmptyDivider />
    <CollabSection />
    <EmptyDivider />
    <GoalSection />
    <EmptyDivider />
    <VideoSection specialId="1" video="https://youtu.be/lFgAjseS8hI" />
    <EmptyDivider />
    <TraditionalSection />
    <EmptyDivider />
    <HypothesisSection />
    <EmptyDivider />
    <BlockchainSection />
    <EmptyDivider />
    <ContributeSection />
    <EmptyDivider />
    {/* <NftsSection />
    <EmptyDivider />
    <VideoSection specialId="2" video="https://www.youtube.com/watch?v=q23lmH-1SUU&ab_channel=ML" />
    <EmptyDivider /> */}
    <ProvenanceSection />
    <EmptyDivider />
    <InnovationSection />
    <EmptyDivider />
    <VotanomicsSection />
    <EmptyDivider />
    <RoadmapSection />
    <EmptyDivider />
    <DownloadSection />
    <NewsletterSection />
    <EmptyDivider />
  </>
);

export default Homepage;
