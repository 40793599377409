import React from 'react';
import ScrollToTop from '../Utils/ScrollToTop';

// Components
import EmptyDivider from '../Utils/EmptyDivider';
import SubHeroSection from '../Components/SubHero';
import PrivacySection from '../Components/PrivacyPolicy';

const privacy = () => (
  <>
    <ScrollToTop />
    <SubHeroSection header="Privacy Policy" heroHeight="20rem" />
    <EmptyDivider />
    <PrivacySection />
    <EmptyDivider />
  </>
);

export default privacy;
