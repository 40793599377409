import styled from 'styled-components';

export const RightsContainer = styled.div`

`;

export const RightsWrapper = styled.div`
display: flex;
flex-direction: column;
width: 100%;
max-width: 1100px;
margin-left: auto;
margin-right: auto;
padding: 0 25px;
`;

export const RightsHeader = styled.h1`
font-size: 28px;
color: #000;
text-align: center;
margin-bottom: 25px;

@media screen and (max-width: 768px){
    font-size: 24px;
}

@media screen and (max-width: 480px){
    font-size: 18px;
}
`;

export const RightsGridWrapper = styled.div`
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 50px;

@media screen and (max-width: 768px){
    grid-template-columns: repeat(1, 1fr);
}
`;

export const RightsCard = styled.div`
display: flex;
flex-direction: column;
background: #F0FFE6;
border-radius: 25px;
border: 2px solid #33682C;

&:last-child {
    @media screen and (min-width: 768px){
        justify-self: center;
        width: 50%;
        grid-column-start: span 2;
    }
}
`;

export const CardImage = styled.img`
width: 100%;
height: auto;
border-radius: 20px 20px 0 0;
`;

export const CardHeader = styled.h3`
font-size: 24px;
color: #33682C;
text-align: center;
margin-top: 25px;
margin-bottom: 15px;

@media screen and (max-width: 768px){
    font-size: 20px;
}

@media screen and (max-width: 480px){
    font-size: 16px;
}
`;

export const CardText = styled.p`
font-size: 18px;
color: #000;
text-align: center;
padding: 20px;

@media screen and (max-width: 768px){
    font-size: 16px;
}

@media screen and (max-width: 480px){
    font-size: 14px;
}
`;

export const CustomLink = styled.a`
color: #000;
`;
