/* eslint-disable max-len */
import React from 'react';
import {
  TermsContainer,
  TermsHeader,
  TermsMainHeader,
  TermsWrapper,
  TermsText,
  Bold,
  TermsList,
  TermsSegment,
  TermsListElement,
} from './termsElements';
import EmptySmallDivider from '../../Utils/EmptyDivider/smallDivider';

const TermsSection = () => (
  <TermsContainer>
    <TermsWrapper>
      <TermsMainHeader>PLEASE READ CAREFULLY</TermsMainHeader>
      {/* Section */}
      <TermsSegment>
        <TermsHeader>1. Introduction of the Terms</TermsHeader>
        <TermsText>
          The
          {' '}
          <Bold>Carbon Collectible NFTs’</Bold>
          {' '}
          website, owned and operated by
          {' '}
          <Bold>Comino Investments SRL</Bold>
          accessible at
          {' '}
          <Bold>https://Carboncollectiblenfts.com</Bold>
          . A company incorporated at Calle R, No. 5, Del
          Sector Zona Industrial Haina de la ciudad de San Cristobal, Dominican Republic (
          <Bold>“Company,”</Bold>
          {' '}
          Carbon Collectible NFT Terms and Conditions
          “we,” “us” or “our”). These Terms of Service (these
          {' '}
          <Bold>“Agreement”</Bold>
          ) govern your access to and use
          of
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          website; including without limitation the participation, purchase, sale, or
          modification of certain digital assets; our mobile and/or online services, provided on or in
          connection with those services (collectively, the
          {' '}
          <Bold>“Service”</Bold>
          ) also via Pre Sale or Sale (
          <Bold>“Pre Sale”</Bold>
          ).
          Detailed rules regarding the Pre Sale process are available on the site.
          {' '}
          <Bold>The Company</Bold>
          {' '}
          is a
          technological platform and not a broker, financial institution, or creditor. Due to the
          decentralized nature of the
          {' '}
          <Bold>Service</Bold>
          , in no stage does
          {' '}
          <Bold>the Company</Bold>
          {' '}
          hold or possess any assets on
          behalf of the users.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>2. Eligibility</TermsHeader>
        <TermsText>
          The
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          NFT Collection is open only to individuals who are 18 years and above and have
          the right and authority to enter into this Agreement, and are fully able and competent to satisfy
          the terms, conditions, and obligations herein.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>3. The Pre-sale</TermsHeader>
        <TermsText>
          You must connect a supported wallet to be waitlisted and purchase using ETH or Matic (Polygon)
          to own a
          {' '}
          <Bold>Company</Bold>
          {' '}
          NFT. This action is necessary to participate in the
          {' '}
          <Bold>Pre Sale</Bold>
          {' '}
          and have access to
          use the site, including performing a Transaction on the site. These digital wallets enable you to
          hold, store, purchase, and engage in credit card, Matic or Ethereum cryptocurrency transactions.
          When you connect your wallet, you understand and agree that:
        </TermsText>
        <EmptySmallDivider />
        <TermsList>
          <TermsListElement>
            You are solely responsible for maintaining the security of your wallet and your control
            over any wallet-related authentication transactions, private or public cryptocurrency
            keys, NFTs or cryptocurrencies that are stored in or are accessible through your wallet;
          </TermsListElement>
          <TermsListElement space="10px">
            Any unauthorized access to your wallet by third parties could result in the loss or theft of
            your
            {' '}
            <Bold>Company</Bold>
            {' '}
            NFTs and/or funds held in your wallet and any associated wallets, including
            any linked financial information such as bank account(s) or credit card(s) that might be
            tampered with;
          </TermsListElement>
          <TermsListElement space="10px">
            The
            {' '}
            <Bold>Company</Bold>
            {' '}
            is not responsible for maintaining the security of your cryptocurrency
            wallet;
          </TermsListElement>
          <TermsListElement space="10px">
            The
            {' '}
            <Bold>Company</Bold>
            {' '}
            has no responsibility or liability to you for any unauthorized access to or
            use of your cryptocurrency wallet or if you are unable to locate your credentials;
          </TermsListElement>
        </TermsList>
        <EmptySmallDivider />
        <TermsText>
          If you notice any unauthorized or suspicious activity in your wallet, please notify our support
          channel immediately.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>4. Price</TermsHeader>
        <TermsText>
          The
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          NFT price will be determined at the moment of Your purchase. You expressly
          agree that the price is subject to change. By participating in the purchase of the
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          NFT,
          the buyer confirms that he/she is in compliance with the regulations of his/her country and the
          <Bold>Company’s</Bold>
          {' '}
          terms and conditions.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>5. The NFT Project</TermsHeader>
        <TermsText>
          <Bold>The Company’s</Bold>
          {' '}
          NFT features a series of creative art work. The goal of the NFT sale is to add equity
          and inclusion to climate finance for low-income, old growth tropical forest communities. Users
          can mint the
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          NFTs from a dedicated website (&quot;Sale&quot;). The Company will provide the
          web address of the collection at the time of the mint. Acquiring
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          NFT does not confer
          any rights to the Company or to the physical land or trees.
        </TermsText>
        <EmptySmallDivider />
        <TermsText>
          The
          {' '}
          <Bold>Company</Bold>
          {' '}
          agrees to use its best efforts to grant Purchasers of the NFT right to one dedicated
          hectare of mature forest land (One hectare is 2.47 acres). This includes pay-to-preserve rights for
          carbon removal but no physical rights to the land or trees. As a purchaser of the NFT, you agree
          not to copy the NFT or reproduce the
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          idea, or business model, in any way without
          the express written permission of the
          {' '}
          <Bold>Company</Bold>
          .
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>6. No Representation</TermsHeader>
        <TermsText>
          You shall bear full responsibility for verifying the identity, legitimacy, and authenticity of the
          assets you purchase on the site.
        </TermsText>
        <EmptySmallDivider />
        <TermsText>
          These terms and conditions are important and affect your legal rights, so please read them
          carefully. We want to let you know that the terms include a user license which will, with limited
          exceptions, bind you within the terms and conditions herein.
        </TermsText>
        <EmptySmallDivider />
        <TermsText>
          By clicking on “i accept” button, participation in the Pre-sale process, using our services and/or
          purchasing crypto assets, you agree to be bound by these terms and all of the terms incorporated
          herein by reference. If you do not agree to these terms, you may not access or use the service or
          purchase the crypto assets.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>7. User Licensed Rights</TermsHeader>
        <TermsText>
          We hereby grant you the license rights described below;
        </TermsText>
        <EmptySmallDivider />
        <TermsList>
          <TermsListElement>
            <Bold>Virtual Rights:</Bold>
            {' '}
            Virtual Rights to 1 hectare of forest land for the duration of your NFT. This
            includes mixed reality rights to biodiversity, virtual tourism, gaming, metaverse and
            carbon sequestration measured using remote satellite imagery and /or manual validation.
          </TermsListElement>
          <TermsListElement space="10px">
            <Bold>Physical Rights:</Bold>
            {' '}
            Does not convey to you any physical rights associated with the trees, land
            or any other physical feature associated with the forest. Furthermore, the Company is
            not an investment contract and does not convey any ownership rights to the forest or any
            project or business.
          </TermsListElement>
          <TermsListElement space="10px">
            <Bold>Copyright:</Bold>
            {' '}
            We grant you a Copyright to the artwork related to your Company NFT under
            the creative commons license BY-SA 4.0. This artwork is licensed to the NFT holder under
            a CC BY-SA 4.0 license.
          </TermsListElement>
        </TermsList>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>8. Ownership</TermsHeader>
        <TermsText>
          If you purchase the
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          NFT, you own the NFT and the licensed rights attached to each
          NFT. Subject to the legal jurisdiction in which you reside,
          {' '}
          <Bold>the Company</Bold>
          {' '}
          grants you a worldwide,
          royalty-free license to personal and non-commercial use, copy, and display the purchased
          collectibles, along with any extensions that you choose to create or use, solely for your own
          personal, non-commercial use.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>9. Copyright Policy</TermsHeader>
        <TermsText>
          The Website contains valuable trademarks and service marks owned and used by affiliates of the
          Company, including but not limited to, name, pictures, logos, videos, the NFT design, and
          (collectively, the &quot;intellectual Property&quot; or “IP”). Any use of the
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          Marks without the
          prior written permission of the
          {' '}
          <Bold>Company</Bold>
          {' '}
          is strictly prohibited. All content on
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          website
          is subject to Copyright(c) 2022 protection and to the expressed these terms and conditions
          herein.
        </TermsText>
        <EmptySmallDivider />
        <TermsText>
          If you believe that any content, or other material made available on
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          website,
          including Company’s NFTs, infringes upon any NFT designs is a copyright that you own or control,
          you may notify us in writing.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>10. Restriction</TermsHeader>
        <TermsText>
          Owning a
          {' '}
          <Bold>Company</Bold>
          {' '}
          NFT does not grant you the rights to duplicate, steal, remake, or redo any
          idea associated with
          {' '}
          <Bold>the NFT</Bold>
          .
          You are not permitted to separate any rights from your NFT. If you transfer ownership of your
          NFT then you are also transferring all associated rights and restrictions described herein.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>11. Refunds concerns</TermsHeader>
        <TermsText>
          When you purchase a
          {' '}
          <Bold>Company</Bold>
          {' '}
          NFT, your transaction will be conducted on the Ethereum or
          Matic (Polygon) network, please see the terms and privacy policy. The
          {' '}
          <Bold>Company</Bold>
          {' '}
          has no control
          over such transactions or the blockchain network, cannot reverse or issue refunds in connection
          with such transactions, and we shall not be liable for any loss, damage or harm that you may
          incur as a result of such transactions.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>12. Warranty Disclaimer</TermsHeader>
        <TermsText>
          Your use of the site, and NFT is at your sole risk. except as expressly provided to the contrary in
          writing by the
          {' '}
          <Bold>Company</Bold>
          {' '}
          , The site, and any
          {' '}
          <Bold>Company NFT</Bold>
          {' '}
          collectibles are provided on an “as is”
          and “as available” basis without warranties or conditions of any kind, either express or implied.
        </TermsText>
        <EmptySmallDivider />
        <TermsText>
          <Bold>The Company’s</Bold>
          {' '}
          NFTs are intangible digital assets. They exist only by virtue of the ownership
          record maintained in the ethereum network. Any transfer of title that might occur in any unique
          digital asset occurs on the decentralized ledger within the ethereum or Matic platform.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>13. Limitation Of Liability</TermsHeader>
        <TermsText>
          As the law permits us, in no event will
          {' '}
          <Bold>the Company</Bold>
          {' '}
          or its licensors be liable to you or any third
          party for any loss of profit or any indirect, consequential, exemplary, incidental, special or
          punitive damages arising from these terms, the site, or the
          {' '}
          <Bold>Company</Bold>
          . Access to, and use of, the
          site, are at your own discretion and risk. You will be solely responsible for any damage to you,
          your computer system or mobile device or loss of data resulting therefrom.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>14. Force Majeure Event</TermsHeader>
        <TermsText>
          <Bold>The Company</Bold>
          {' '}
          shall not be liable for its failure to perform any of its obligations to the Purchaser
          hereunder, during any period in which such performance is delayed by circumstances beyond its
          reasonable control (&quot;Force Majeure Event&quot;). The Parties expressly agree that a failure of a
          blockchain protocol or technology (e.g., a wallet) will be considered as a Force majeure Event.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>15. Amendment or Update</TermsHeader>
        <TermsText>
          At its sole discretion and without prior notice, the
          {' '}
          <Bold>Company</Bold>
          {' '}
          may amend, change, update or add
          to the current Terms and Conditions. In this event, you will receive a notification and have the
          right (i) to accept the update or (ii) to stop participating in the Sale. If You continue to participate,
          you are deemed to have accepted the amendment.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>16. Consumer Privacy Acts</TermsHeader>
        <TermsText>
          You are entitled to the following specific consumer rights information:
        </TermsText>
        <EmptySmallDivider />
        <TermsList>
          <TermsListElement>
            You can contact us in writing at:
            {' '}
            <Bold>contact@carboncollectiblenfts.com</Bold>
            , or by telephone
            by calling: (829) 994-8585.
          </TermsListElement>
          <TermsListElement space="10px">
            Complaints. The procedures that you may follow in order to resolve a complaint
            regarding the Site, its content, or NFT purchased through the Site are as follows:
            Contact the
            {' '}
            <Bold>Company</Bold>
            {' '}
            resolve a complaint.
          </TermsListElement>
        </TermsList>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>17. Governing Law</TermsHeader>
        <TermsText>
          These Terms and the NFTs offered herein shall be governed and construed under the Dominican
          Republic laws without regard to its conflict of law provisions.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>18. Miscellaneous</TermsHeader>
        <TermsText>
          These Terms and Conditions shall constitute the entire agreement between The
          {' '}
          <Bold>Company</Bold>
          {' '}
          and
          you (Purchaser) and also govern your use of the site, including the
          {' '}
          <Bold>Company’s</Bold>
          {' '}
          NFTs. Except as
          expressly provided herein, these agreements are intended solely for the benefit of the parties
          and are not intended to confer third party beneficiary rights upon any other person or entity.
        </TermsText>
        <EmptySmallDivider />
        <TermsText>
          The titles of the Sections in these Terms have no legal or contractual effect but are for
          convenience only. We will provide Notices to you via email or provide notices to you of changes
          to these Terms or other features by displaying notice changes on the specific page.
        </TermsText>
      </TermsSegment>
      {/* Section */}
      <TermsSegment space="2rem">
        <TermsHeader>19. Support</TermsHeader>
        <TermsText>
          If you have any questions or comments about the NFTs, site or these Terms please contact our
          customer support team at
          {' '}
          <Bold>contact@carboncollectiblenfts.com</Bold>
        </TermsText>
      </TermsSegment>
    </TermsWrapper>
  </TermsContainer>
);

export default TermsSection;
