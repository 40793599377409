/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md';
import {
  GoalButton,
  GoalButton2,
  GoalButtonWrapper,
  GoalContainer,
  GoalH1,
  GoalHeader,
  GoalP,
  GoalSliderWrapper,
  GoalTextWrapper,
  GoalWrapper,
  ScrollText,
} from './GoalElements';
import Card from '../../Utils/Card';
import img1 from '../../Images/Goal/goal-1.webp';
import img2 from '../../Images/Goal/goal-2.webp';
import img4 from '../../Images/Goal/goal-4.webp';
import img5 from '../../Images/Goal/goal-5.webp';
import img6 from '../../Images/Goal/goal-6.webp';

const GoalSection = () => {
  let isDown = false;
  let mainSlider;
  let startX;
  let scrollLeftd;

  const slideLeft = () => {
    const slider = document.getElementById('slider1');
    slider.scrollLeft += 500;
  };

  const slideRight = () => {
    const slider = document.getElementById('slider1');
    slider.scrollLeft -= 500;
  };

  const sliderDown = (e) => {
    isDown = true;
    startX = e.pageX - mainSlider.offsetLeft;
    scrollLeftd = mainSlider.scrollLeft;
    mainSlider.classList.add('active');
  };

  const sliderLeave = () => {
    isDown = false;
    mainSlider.classList.remove('active');
  };

  const sliderMove = (e) => {
    if (!isDown) {
      return;
    }
    e.preventDefault();
    const x = e.pageX - mainSlider.offsetLeft;
    const walk = (x - startX) * 2;
    mainSlider.scrollLeft = scrollLeftd - walk;
  };

  useEffect(() => {
    mainSlider = document.getElementById('slider1');
    mainSlider.addEventListener('mousedown', sliderDown);
    mainSlider.addEventListener('mouseleave', sliderLeave);
    mainSlider.addEventListener('mouseup', sliderLeave);
    mainSlider.addEventListener('mousemove', sliderMove);
  }, []);

  return (
    <GoalContainer id="project">
      <GoalWrapper>
        <GoalHeader>About Our Project</GoalHeader>
        <GoalTextWrapper>
          <GoalH1>
            The goal of our project is to
            {' '}
            <span style={{ color: '#33682C' }}>minimize poverty-driven deforestation</span>
          </GoalH1>
          <GoalButtonWrapper>
            <GoalButton onClick={slideRight}><MdArrowBackIos style={{ marginTop: '10px', marginLeft: '10px' }} /></GoalButton>
            <GoalButton2 onClick={slideLeft}><MdArrowForwardIos style={{ marginTop: '10px', marginLeft: '2px' }} /></GoalButton2>
          </GoalButtonWrapper>
        </GoalTextWrapper>
        <ScrollText style={{ paddingBottom: '25px' }}>{'<<< drag left and right >>>'}</ScrollText>
        <GoalTextWrapper>
          <GoalP>
            We reduce poverty-driven deforestation by adding equity and
            inclusion to climate finance and by increasing the value of
            forests to their local communities.
          </GoalP>
        </GoalTextWrapper>
      </GoalWrapper>
      <GoalSliderWrapper data-aos="fade" data-aos-once="true" data-aos-delay="100" id="slider1">
        <Card first image={img1} desc="We add biodiverse personality to accredited carbon credits for forests that are certified by one of the major international carbon Registries. Certified carbon credits are not usually geolocated." />
        <Card image={img4} desc="Our SatNav App estimates the net carbon removal rate per hectare, which it uses as a guide to assign a pool of carbon credits to each hectare. This process geolocates certified carbon credits." />
        <Card image={img5} desc="To each NFT, we assign rights to 1 hectare of a forest. This bundles sponsorship rights for that hectare's biodiversity with that hectare's assigned number of certified carbon credits." />
        <Card image={img2} desc="We geolocate sites of interest in each forest, such as waterfalls, caves, medicinal trees and endangered species. This enables us to add rarity icons to your NFT that represent it's geolocated biodiversity." />
        <Card last image={img6} desc="We pay a fee, from each NFT sale, to fund forest management and security, then use 50% of the net proceeds to finance regenerative entrepreneurship that multiplies, recycles and compounds your ESG sponsorship." />
      </GoalSliderWrapper>
    </GoalContainer>
  );
};

export default GoalSection;
